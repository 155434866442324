var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('div',[_c('f7-row',[_c('f7-col',{attrs:{"width":"25","medium":"30"}},[_c('DxTreePlanning',{attrs:{"locations-tree":_vm.locationsTree,"planning":{locations: _vm.actualFarm},"only-level0":true,"show-grouped":false}})],1),_vm._v(" "),_c('f7-col',{attrs:{"width":"75","medium":"70"}},[_c('DxForm',{attrs:{"id":"form","col-count":1,"caption":"Employee Data"}},[_c('DxGroupItem',[_c('DxGroupItem',{attrs:{"caption":_vm.$t('EmployeeComponent_caption_text')}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('EmployeeComponent_name_caption'),"name":"name","caption":_vm.$t('EmployeeComponent_name_caption'),"editor-options":{
                value: _vm.actualName,
                mode: 'text',
                placeholder: _vm.$t('EmployeeComponent_name_caption'),
                showClearButton: true,
                onValueChanged: _vm.setNameChanged,
              }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxEmployeeForm_name_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('EmployeeComponent_surname_caption'),"name":"surname","caption":_vm.$t('EmployeeComponent_surname_caption'),"editor-options":{
                value: _vm.actualSurname,
                mode: 'text',
                placeholder: _vm.$t('EmployeeComponent_surname_caption'),
                showClearButton: true,
                onValueChanged: _vm.setSurnameChanged,
              }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxEmployeeForm_surname_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('EmployeeComponent_identificationNumber_caption'),"name":"identificationNumber","caption":_vm.$t('EmployeeComponent_identificationNumber_caption'),"editor-options":{
                value: _vm.actualIdentificationNumber,
                mode: 'text',
                placeholder: _vm.$t('EmployeeComponent_identificationNumber_caption'),
                showClearButton: true,
                onValueChanged: _vm.setIdentificationNumberChanged,
              }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxEmployeeForm_ID_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('EmployeeComponent_status_caption'),"editor-options":{
                items: _vm.itemsStatus,
                searchEnabled: true,
                searchTimeout: _vm.searchTimeoutOption,
                minSearchLength: _vm.minSearchLengthOption,
                showDataBeforeSearch: _vm.showDataBeforeSearchOption,
                onValueChanged: _vm.setStatusEvent,
                value: _vm.actualstatusName,
              },"editor-type":"dxSelectBox","name":"status"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxEmployeeForm_status_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('EmployeeComponent_role_caption'),"editor-options":{
                items: _vm.itemsRole,
                searchEnabled: true,
                searchTimeout: _vm.searchTimeoutOption,
                minSearchLength: _vm.minSearchLengthOption,
                showDataBeforeSearch: _vm.showDataBeforeSearchOption,
                onValueChanged: _vm.setRoleEvent,
                value: _vm.actualRole,
              },"editor-type":"dxSelectBox","name":"role"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxEmployeeForm_role_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('EmployeeComponent_type_caption'),"editor-options":{
                items: _vm.itemsType,
                searchEnabled: true,
                searchTimeout: _vm.searchTimeoutOption,
                minSearchLength: _vm.minSearchLengthOption,
                showDataBeforeSearch: _vm.showDataBeforeSearchOption,
                onValueChanged: _vm.setTypeEvent,
                value: _vm.actualType,
              },"editor-type":"dxSelectBox","name":"type"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxEmployeeForm_type_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('EmployeeComponent_drive_caption'),"editor-options":{
                onValueChanged: _vm.setDriveChenged,
                value: _vm.actualDrive,
              },"editor-type":"dxCheckBox","name":"drive"}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('EmployeeComponent_applicator_caption'),"name":"applicator","caption":_vm.$t('EmployeeComponent_applicator_caption'),"editor-type":"dxCheckBox","editor-options":{
                value: _vm.applicator,
                onValueChanged: _vm.applicatorChanged,
              }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('EmployeeComponent_expirationDate_caption'),"visible":_vm.expirationDateVisible,"name":"date"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('f7-input',{staticClass:"form-calendar",attrs:{"id":"datepicker-expiration","type":"datepicker","placeholder":_vm.$t('EmployeeComponent_datepicker_expirationDate_placeholder'),"readonly":"","clear-button":"","calendar-params":{openIn: 'customModal', header: false, footer: true, dateFormat: 'dd/mm/yyyy', multiple: false, minDate: _vm.minDate, maxDate: '', disabled:_vm.sundays},"value":_vm.expirationDateSelected},on:{"calendar:change":_vm.expirationDateSelectedEvent}})]},proxy:true}],null,false,1407682121)}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('EmployeeComponent_email_caption'),"name":"email","caption":_vm.$t('EmployeeComponent_email_caption'),"visible":_vm.isSupervisor,"editor-options":{
                value: _vm.actualEmail,
                mode: 'email',
                placeholder: _vm.$t('EmployeeComponent_email_caption'),
                showClearButton: true,
                onValueChanged: _vm.setEmailChanged,
              }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxEmployeeForm_email_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('EmployeeComponent_password_caption'),"name":"password","visible":_vm.isSupervisor,"caption":_vm.$t('EmployeeComponent_password_caption'),"editor-options":{
                value: _vm.actualPassword,
                mode: 'text',
                placeholder: _vm.$t('EmployeeComponent_password_caption'),
                showClearButton: true,
                onValueChanged: _vm.setPasswordChanged,
              }}})],1),_vm._v(" "),_c('DxGroupItem',[_c('DxGroupItem',{attrs:{"name":"","caption":_vm.$t('EmployeeComponent_identification_title')}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('EmployeeComponent_typeIdentification_caption'),"editor-options":{
                  dataSource: ['NFC'],
                  searchEnabled: true,
                  searchTimeout: _vm.searchTimeoutOption,
                  minSearchLength: _vm.minSearchLengthOption,
                  showDataBeforeSearch: _vm.showDataBeforeSearchOption,

                  onValueChanged: _vm.setTypeIdentificationEvent,
                  value: _vm.actualTypeIdentification,
                },"editor-type":"dxSelectBox","name":"typeIdentification","caption":_vm.$t('EmployeeComponent_typeIdentification_caption')}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('EmployeeComponent_code_caption'),"name":"codeIdentification","caption":_vm.$t('EmployeeComponent_code_caption'),"editor-options":{
                  value: _vm.actualCode,
                  mode: 'text',
                  placeholder: _vm.$t('EmployeeComponent_code_caption'),
                  showClearButton: true,
                  onValueChanged: _vm.setCodeChanged,
                }}})],1)],1)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }