<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-work-orders main-dx-taskorders"
  >
    <f7-row>
      <f7-col>
        <ExportDataToExcel
          :fetch-tasks="true"
          :key-name="keyName"
          :supervisors-availables="supervisorsAvailables"
        />
      </f7-col>
      <f7-col>
        <CalendarInitEndDate :key-name="keyName" />
      </f7-col>
    </f7-row>
    <DxDataGrid
      id="gridContainer"
      :data-source="detailpostWortJson"
      :remote-operations="true"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :row-alternation-enabled="true"
      @toolbar-preparing="onToolbarPreparing($event)"
      @exporting="onExporting"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxTaskOrders"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="true"
      />
      <DxSelection
        mode="single"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :highlight-case-sensitive="true"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        :buttons="editButtons"
        type="buttons"
        width="90"
        data-field="Actions"
      />
      <DxColumn
        data-field="shortId"
        name="shortId"
        :caption="$t('DxWorkOrder_short_id')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="Date"
        name="date"
        :caption="$t('DxDailyPlanning_date_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="Activity"
        name="Activity"
        :caption="$t('DxEffort_activity_caption')"
        :allow-header-filtering="true"
        css-class="bold"
      />
      <DxColumn
        data-field="Task"
        name="task"
        :caption="$t('DxDailyPlanning_task_caption')"
        :allow-header-filtering="true"
        css-class="bold"
      />
      <DxColumn
        data-field="locationLevel2"
        name="location"
        :caption="$t('DxDailyPlanning_location_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="Workers"
        name="workers"
        :caption="$t('DxDailyPlanning_workers_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="Supervisor"
        name="supervisor"
        :caption="$t('DxDailyPlanning_supervisors_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="isOt"
        name="isOt"
        :caption="$t('DxTaskOrder_origen')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="additionalInfoWorkOrder"
        :caption="$t('DxWorkOrders_notes_caption')"
        :allow-header-filtering="false"
        alignment="left"
        css-class="bold"
      />
      <DxMasterDetail
        :enabled="true"
        template="master-detail"
      />
      <template #master-detail="{ data: detailpostWortJson }">
        <DxDetailTabs
          :current-task-id="detailpostWortJson.data.ID"
          :locations-tree="locationTree"
        />
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxMasterDetail,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import moment from 'moment';
import saveAs from 'file-saver';
import * as overlay from 'devextreme/ui/overlay';
import DxDetailTabs from './DxDetailTabs.vue';
import Api from '../../services/Api';
import CalendarInitEndDate from '../CalendarInitEndDate.vue';
import ExportDataToExcel from '../ExportDataToExcel.vue';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxMasterDetail,
    DxDetailTabs,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    CalendarInitEndDate,
    DxPager,
    DxSelection,
    ExportDataToExcel,
  },
  data() {
    return {
      loaded: false,
      detailpostWortJson: [],
      finalLevelLocation: [],
      supervisorsAvailables: [],
      workOrderStatusTranslated: '',
      ocultarBotton: false,
      editButtons: [{
        icon: 'find',
        hint: this.$t('DxWorkOrder_Button_View_hint'),
        visible: this.isViewconVisible,
        onClick: this.viewtask,
      },
      // {
      //   icon: 'edit',
      //   hint: this.$t('DxWorkOrders_edit_hint'),
      //   visible: this.isEditIconVisible,
      //   onClick: this.editPostWork,
      // },
      ],
      keyName: 'postWork',
      pageSizes: [5, 10, 20],
      nameFileToExport: '',
      arrayExcelColumnTranslations: [],
      detailDailyTasksToExport: [],
    };
  },
  computed: {
    ...mapGetters('authentication', ['getPermissions']),
    ...mapState('PlanningManager', ['back']),
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
    ...mapState('greenhouse', ['locationTree']),
    ...mapGetters('greenhouse', ['getVarietyNameByCode', 'getCategoryNameByCode', 'getClassificationNameByCode', 'getLocationNameLevelSelectedChildId']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      await this.fetchLocationTree();
      this.supervisorsAvailables = await this.getSupervisors();
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      const postWork = await Api.fetchTasks(false, [], [], checkedInitEndDate.init, checkedInitEndDate.end, true);
      const postWortJson = JSON.parse(postWork.response).data;
      this.detailpostWortJson.length = 0;
      for (let i = 0; i < postWortJson.length; i += 1) {
        const items = this.getItems(postWortJson[i].items_drivers);
        // Establedemos los nombres de variedad/categoria/clasificacion a través del code
        if (postWortJson[i].nursery) {
          postWortJson[i].nursery.forEach((element) => {
            const varietyName = this.getVarietyNameByCode(element.variety);
            const categoryName = this.getCategoryNameByCode(element.category);
            const classificationName = this.getClassificationNameByCode(element.class_type);
            element.variety = varietyName !== '' ? varietyName : element.variety;
            element.category = varietyName !== '' ? categoryName : element.category;
            element.class_type = classificationName !== '' ? classificationName : element.class_type;
          });
        }
        if (postWortJson[i].planner != null) {
          const newDetailPostWork = {
            ID: postWortJson[i].id,
            Date: this.formatoDate(postWortJson[i].date),
            Task: postWortJson[i].task,
            Activity: postWortJson[i].activity,
            activityCode: postWortJson[i].activity_code,
            TaskStatus: postWortJson[i].status,
            WorkOrderStatus: postWortJson[i].worder_status,
            Supervisor: this.getNameSupervisor(postWortJson[i].responsible),
            Workers: `${this.getTotalWorkers(postWortJson[i].workers)} / ${this.getTotalWorkersPlanner(postWortJson[i].planner.workers)}`,
            items,
            locationLevel2: this.getLocationsFormated(postWortJson[i].locations_tree, postWortJson[i].location, postWortJson[i].activity_code),
            location: postWortJson[i].location,
            Planner: postWortJson[i].planner,
            responsible: postWortJson[i].responsible,
            additionalInfoWorkOrder: postWortJson[i].comment,
            area_effective: postWortJson[i].area_effective,
            variety: postWortJson[i].planner.variety === null || postWortJson[i].planner.variety === 'ALL' ? '' : postWortJson[i].planner.variety,
            shortId: postWortJson[i].short_id,
            isOt: postWortJson[i].planner.planner_id === null ? 'Plan' : 'Manual',
          };
          this.detailpostWortJson.push(newDetailPostWork);
        }
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    async viewtask(rowSelected) {
      try {
        const currentTaskFormatted = await this.getTask(rowSelected.row.data.ID);
        if (currentTaskFormatted.TaskStatus === 'COMPLETED') {
        // Comprobación para editar productos / prescripcion
          if (currentTaskFormatted.Planner.prescription) {
            this.setPrescription(currentTaskFormatted.Planner);
          }
          // Comprobación para editar tools
          if (currentTaskFormatted.Planner) {
            currentTaskFormatted.ToolsDetailTab = currentTaskFormatted.Planner.tools;
          }
          this.setActualWorkOrder(currentTaskFormatted);
          this.setProducts(currentTaskFormatted.products);
          this.setKeyName(this.keyName);
          this.$f7.views.main.router.navigate('/ViewPostWork/', { reloadCurrent: true });
        }
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      }
    },
    getItems(itemsDriver) {
      const items = [];
      for (const itemDriver of itemsDriver) {
        items.push(itemDriver.item);
      }
      return items;
    },
    isViewconVisible(e) {
      if (e.row.data.WorkOrderStatus === 'COMPLETED' && e.row.data.TaskStatus === 'COMPLETED') {
        return true;
      }
      if (e.row.data.TaskStatus === 'IN_PROGRESS' || e.row.data.TaskStatus === 'OPEN' || e.row.data.WorkOrderStatus !== 'COMPLETED') {
        return false;
      }
      return true;
    },
    async editPostWork(rowSelected) {
      if (rowSelected.row.data.TaskStatus === 'COMPLETED') {
        // Comprobación para editar productos / prescripcion
        if (rowSelected.row.data.Planner.prescription) {
          this.setPrescription(rowSelected.row.data.Planner);
        }
        // Comprobación para editar tools
        if (rowSelected.row.data.Planner) {
          rowSelected.row.data.ToolsDetailTab = rowSelected.row.data.Planner.tools;
        }
        try {
          this.$f7.preloader.show();
          const xhr = await Api.getDataWorkOrder(rowSelected.row.data.ID);
          const dataWorkOrder = JSON.parse(xhr.response);
          this.setActualWorkOrder(dataWorkOrder);

          this.setProducts(rowSelected.row.data.products);
          this.$f7.views.main.router.navigate(`/editWorkOrder/${this.back}/`, { reloadCurrent: true });
          this.setBack('postWork');
        } catch (error) {
          const message = this.$helpers.getFilteredErrorMessage(error);
          if (message.includes('default_') && !message.includes('404')) {
            this.$f7.dialog.alert(message.split('_').pop());
          } else if (!message.includes('404')) {
            this.$f7.dialog.alert(this.$t(message));
          }
        } finally {
          this.$f7.preloader.hide();
        }
      }
    },
    // POR CADA FILA QUE SEA TASKSTATUS = COMPLETED Y WORKWORDERSTATUS = COMPLETED DESHABILITO LOS BOTONES EDITAR Y BORRAR
    isEditIconVisible(e) {
      if (e.row.data.WorkOrderStatus === 'COMPLETED' && e.row.data.TaskStatus === 'COMPLETED'
      && this.$helpers.havePermission(this.getPermissions.company_update_master_data)) {
        return true;
      }
      if (e.row.data.TaskStatus === 'IN_PROGRESS') {
        return false;
      }
      return false;
    },
    // Cambio el formato de Date en formato: 10/05/2020
    formatoDate(date) {
      const dateMoment = moment(date);
      return dateMoment.format('YYYY-MM-DD');
    },
    getLocationsFormated(locationsCompleted, ids, activityCode) {
      if (locationsCompleted.length !== 0) {
        const locationsNameCompleted = [];
        this.$helpers.fetchLocationsNames(locationsNameCompleted, locationsCompleted, this.locationTree);
        return locationsNameCompleted;
      }
      return this.getNamesByBdIds(ids, activityCode);
    },
    getNamesByBdIds(ids, activityCode) {
      const names = [];
      const level = this.$helpers.getLevelLocationToDisplay(activityCode);
      for (const id of ids) {
        const newName = this.getLocationNameLevelSelectedChildId(id, level);
        if (names.indexOf(newName) === -1) {
          names.push(newName);
        }
      }
      return names;
    },
    // GET WORKERS - SUPERVISORS
    async getSupervisors() {
      const xhr = await Api.getSupervisors();
      return JSON.parse(xhr.response).data;
    },
    getNameSupervisor(idSupervisor) {
      for (const supervisor of this.supervisorsAvailables) {
        if (supervisor.id === idSupervisor) {
          return `${supervisor.name} ${supervisor.surname}`;
        }
      }
      return '';
    },
    getTotalWorkers(workers) {
      return workers.length;
    },
    getTotalWorkersPlanner(workersPlanner) {
      let element = 0;
      for (let i = 0; i < workersPlanner.length; i += 1) {
        element += workersPlanner[i].count;
      }
      return element;
    },
    setClassByWorkOrderStatus(status) {
      let colorCell = '';
      switch (status) {
        case 'COMPLETED':
          colorCell = 'work-order-closed';
          this.workOrderStatusTranslated = this.$t('Closed');
          break;
        case null:
        default:
          colorCell = 'work-order-not-closed';
          this.workOrderStatusTranslated = this.$t('Not Closed');
          break;
      }
      return colorCell;
    },
    calculateProductivity(workers) {
      let productivityTotal = 0;
      for (const worker of workers) {
        productivityTotal += parseFloat((worker.unit === '') ? worker.working_area : worker.performance);
      }
      return productivityTotal.toFixed(2);
    },
    calculateRealAreaEfective(workers) {
      let totalArea = 0;
      workers.forEach((w) => {
        totalArea += parseFloat(w.working_area);
      });
      return parseFloat(totalArea).toFixed(2);
    },
    getUnit(planner) {
      if (planner.goal_performance.unit === '') {
        return 'HA';
      }
      return planner.goal_performance.unit;
    },
    getVehicleDrivers(drivers) {
      let toReturn = '';
      drivers.forEach((element) => {
        const vehicle = `${element.item_data.description} ${element.item_data.code}:`;
        const driver = `${element.driver_data.name} ${element.driver_data.surname}`;
        toReturn += `${vehicle} ${driver};`;
      });
      return toReturn;
    },
    getGoalPerformance(planner) {
      if (planner.goal_performance.unit === '') {
        return '';
      }
      return `${parseFloat(planner.goal_performance.count).toFixed(2)}`;
    },
    async getTask(id) {
      this.$f7.preloader.show();
      try {
        const xhr = await Api.getTaskByID(id);
        const currentTask = JSON.parse(xhr.response);
        const currentTaskFormatted = {
          ID: currentTask.id,
          Date: moment(currentTask.date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
          Task: currentTask.task,
          taskCode: currentTask.task_code,
          Activity: currentTask.activity,
          activityCode: currentTask.activity_code,
          TaskStatus: currentTask.status,
          WorkOrderStatus: currentTask.worder_status,
          workOrderStatusTemplateClass: this.setClassByWorkOrderStatus(currentTask.worder_status),
          workOrderStatusTranslated: this.workOrderStatusTranslated,
          Supervisor: this.getNameSupervisor(currentTask.responsible),
          Workers: `${this.getTotalWorkers(currentTask.workers)} / ${this.getTotalWorkersPlanner(currentTask.planner.workers)}`,
          WorkersDetailTab: currentTask.workers,
          ItemsDetailTab: this.getItems(currentTask.items_drivers),
          Attachments: currentTask.attachments_vehicles,
          AttachmentsDetailTab: currentTask.attachments,
          Tools: currentTask.planner.tools,
          ToolsDetailTab: currentTask.tools,
          itemsTypes: currentTask.planner.items,
          items: this.getItems(currentTask.items_drivers),
          itemsDriversWorkOrder: currentTask.items_drivers,
          locationLevel2: this.getNamesByBdIds(currentTask.location, currentTask.activity_code),
          area_effective: currentTask.area_effective,
          real_area_effective: this.calculateRealAreaEfective(currentTask.workers),
          goal_performance: this.getGoalPerformance(currentTask.planner),
          location: currentTask.location,
          Planner: currentTask.planner,
          responsible: currentTask.responsible,
          additionalInfoWorkOrder: currentTask.comment,
          machineryPopup: currentTask.machinery_performance,
          products: currentTask.products,
          prescription: currentTask.prescription,
          productPopup: currentTask.product_performance,
          variety: currentTask.variety,
          binsDetail: currentTask.nursery,
          short_id: currentTask.short_id,
          manualPerformance: currentTask.performance,
          supervisorsLocation: currentTask.responsible,
          group: currentTask.planner.group,
          shift: currentTask.planner.shift,
          shortId: currentTask.short_id,
          productivity: this.calculateProductivity(currentTask.workers),
          unit: this.getUnit(currentTask.planner),
          drivers: this.getVehicleDrivers(currentTask.items_drivers),
        };
        return currentTaskFormatted;
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
      }
      return null;
    },
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('WorkOrders');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'WorkOrders.xlsx');
          });
      });
      e.cancel = true;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          text: this.$t('DxTaskOrders_text'),
          onClick: () => {
            this.$f7.views.main.router.navigate('/createPostWork/', { reloadCurrent: true });
          },
        },
      });
    },
    ...mapActions('PlanningManager', ['setActualWorkOrder', 'setVisiblePopupWorkOrder', 'setBack', 'setKeyName']),
    ...mapActions('Prescription', ['setProducts', 'setPrescription', 'setNewPrescription']),
    ...mapActions('greenhouse', ['fetchLocationTree']),
  },
};
</script>
<style scoped>
.content-status-cell {
  padding: 0;
}
.open, .in-progress, .completed, .pending, .deleted, .rejected, .work-order-closed, .work-order-not-closed, .work-order-draft {
  border-radius: 2px;
  font-size: 12px;
  padding: 4px 2px;
  font-weight: 600;
  text-transform: uppercase;
}

.open {
  color: var(--fr-open);
  background: var( --f7-checkbox-inactive-color);
}
.in-progress {
  background: var(--ha-yellow);
  color: var(--fr-inprogress-tint);
}
.completed {
  background: var(--fr-finished);
  color: var(--fr-finished-tint);
}
.pending {
  color: var(--fr-pending);
  background: var(--fr-pending-tint);
}
.deleted {
  color: var(--fr-deleted);;
  background: var(--fr-deleted-tint);
}
.work-order-closed{
  color: var(--ha-dark-green);
  background: var(--ha-light-green);
}
.work-order-not-closed{
  color: #ffffff;
  background: var(--fr-nutella);
}
.work-order-draft {
  color: #fff;
  background: var(--fr-inprogress);
}
</style>
