<template>
  <div
    v-if="loaded"
    class="content-devx"
  >
    <CalendarInitEndDate :key-name="keyName" />
    <DxDataGrid
      id="DxHarvestByDays-grid"
      :data-source="harvestReport"
      :column-min-width="90"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
    >
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxHarvestByDays"
      />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumnFixing :enabled="true" />
      <DxColumn
        data-field="date"
        :caption="$t('DxReportingHarvest_date')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="total"
        :caption="$t('DxReportingHarvest_totalKgDay')"
        data-type="number"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="mechanical"
        :caption="$t('DxReportingHarvest_mechanical')"
        data-type="number"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="manual"
        :caption="$t('DxReportingHarvest_manual')"
        data-type="number"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="accumulated"
        :caption="$t('DxReportingHarvest_accumulated')"
        data-type="number"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxScrolling column-rendering-mode="virtual" />
    </DxDataGrid>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxScrolling,
  DxColumnFixing,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import Api from '../../../services/Api';
import CalendarInitEndDate from '../../CalendarInitEndDate.vue';

export default {
  name: 'DxHarvestByDays',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
    DxStateStoring,
    CalendarInitEndDate,
    DxPager,
  },
  data() {
    return {
      loaded: false,
      keyName: 'reportingHarvestByDays',
      pageSizes: [5, 10, 20],
      harvestReport: [],
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      const xhr = await Api.getReportHarvestByDays(checkedInitEndDate.init, checkedInitEndDate.end);
      this.harvestReport = JSON.parse(xhr.response).data;
      this.setHarvestReport(this.harvestReport);
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('HarvestReportByDays');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'HarvestReportByDays.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('Reporting', ['setHarvestReport']),
  },
};
</script>
