<template>
  <f7-block v-if="!loaded">
    <div class="loadingFalse">
      <div class="preloader" />
    </div>
  </f7-block>
  <f7-block v-else>
    <DxChart
      id="chart"
      :data-source="formattedEffortFiltered"
      :title="this.$t('DxSideBySideTypeofWorker_title_graph')"
      @pointClick="onPointClick"
      @legend-click="onLegendClick"
    >
      <DxCommonSeriesSettings
        argument-field="level"
        type="bar"
        hover-mode="allArgumentPoints"
        selection-mode="allArgumentPoints"
      >
        <DxLabel :visible="true">
          <DxFormat
            :precision="0"
            type="fixedPoint"
          />
        </DxLabel>
      </DxCommonSeriesSettings>
      <DxValueAxis>
        <DxTitle :text="this.$t('DxSideBySideTypeofWorker_number_hours')" />
      </DxValueAxis>
      <DxSeries
        value-field="permanent"
        :name="this.$t('DxSeries_Permanent_name')"
      >
        <DxAggregation
          :enabled="true"
          method="sum"
        />
      </DxSeries>
      <DxSeries
        value-field="temporary"
        :name="this.$t('DxSeries_Temporary_name')"
      >
        <DxAggregation
          :enabled="true"
          method="sum"
        />
      </DxSeries>
      <DxSeries
        value-field="contratist"
        :name="this.$t('DxSeries_Contratist_name')"
      >
        <DxAggregation
          :enabled="true"
          method="sum"
        />
      </DxSeries>
      <DxLegend
        vertical-alignment="bottom"
        horizontal-alignment="center"
      />
      <DxArgumentAxis :aggregate-by-category="true" />
      <DxExport :enabled="true" />
      <DxScrollBar :visible="true" />
      <DxZoomAndPan argument-axis="both" />
    </DxChart>
  </f7-block>
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxLabel,
  DxFormat,
  DxLegend,
  DxExport,
  DxScrollBar,
  DxZoomAndPan,
  DxArgumentAxis,
  DxAggregation,
  DxValueAxis,
  DxTitle,
} from 'devextreme-vue/chart';
import moment from 'moment';
import * as overlay from 'devextreme/ui/overlay';
import { mapState, mapGetters } from 'vuex';
import Api from '../../services/Api';
import EventBus from '../../js/event-bus';

export default {
  name: 'DxSideBySideBarTypeOfWorker',
  components: {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxLabel,
    DxFormat,
    DxLegend,
    DxExport,
    DxScrollBar,
    DxZoomAndPan,
    DxArgumentAxis,
    DxAggregation,
    DxValueAxis,
    DxTitle,
  },
  props: {
    reportingWorker: { type: Array, default: () => [] },
    init: {
      type: String,
      default: moment.utc().startOf('year').format('YYYY-MM-DD'),
    },
    end: {
      type: String,
      default: moment.utc().add(1, 'd').format('YYYY-MM-DD'),
    },
  },
  data() {
    return {
      workerFormated: [],
      effort: [],
      effortFormated: [],
      loaded: false,
      event: 'updateEffort',
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
    ...mapState('Reporting', ['selectedFarmList', 'effortFiltered']),
    formattedEffortFiltered() {
      const effortFormated = [];
      // Se da preferencia a los items seleccionados, en caso de que no haya ninguno
      // se muestran todos los no seleccionados
      for (const items of this.effortFiltered) {
        if (items.items !== null) {
          for (const item of items.items) {
            effortFormated.push(
              item,
            );
          }
        }
      }
      if (effortFormated.length === 0) {
        for (const items of this.effortFiltered) {
          if (typeof items.collapsedItems !== 'undefined') {
            for (const collapsedItem of items.collapsedItems) {
              effortFormated.push(
                collapsedItem,
              );
            }
          }
        }
      }
      return effortFormated;
    },
  },
  mounted() {
    overlay.baseZIndex(9999);
    EventBus.$on(this.event, this.updateLocations);
  },
  beforeDestroy() {
    EventBus.$off(this.event, this.updateLocations);
  },
  methods: {
    async getEffort() {
      const xhr = await Api.getEffortByLocations(this.selectedFarmList, this.init, this.end);
      return JSON.parse(xhr.response);
    },
    formattingEffort() {
      for (const item of this.effort) {
        this.effortFormated.push({
          location: item.parameters.Name,
          hourTemporary: item.parameters.type_hours.TEMPORARY,
          hourPermanent: item.parameters.type_hours.PERMANENT,
          hourContratis: typeof item.parameters.type_hours.PERMANENT === 'undefined' ? 0 : item.parameters.type_hours.PERMANENT,
        });
      }
    },
    onPointClick({ target }) {
      target.select();
    },
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
    async updateLocations() {
      this.loaded = false;
      try {
        this.effort = await this.getEffort();
        this.formattingEffort();
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.loaded = true;
      }
    },
  },
};
</script>
<style>
#chart {
    height: 440px;
}
</style>
