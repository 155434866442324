<template>
  <f7-page>
    <div v-if="loaded">
      <navbar :text="$t('ViewPostWork_Navbar_Title')" />
      <DxViewPlanningWorkOrder
        class="content-planning"
        :actual-work-order="actualWorkOrder"
        :show-tables="true"
        :calendar-multiple="false"
        :max-date="actualDate"
        :distributed-visible="false"
        :working-area-disabled="false"
      />
      <div class="content-devx main-view-post-work">
        <f7-row>
          <f7-col width="100">
            <f7-block-title
              class="no-padding-top"
              name="select_supervisor"
            >
              {{ $t("DetailTabs_supervisors_title") }}:
            </f7-block-title>
            <DxViewWorkOrderSelectSupervisor
              :supervisors-availables="actualWorkOrder.Supervisor"
              :additional-info-work-order="actualWorkOrder.additionalInfoWorkOrder"
            />
          </f7-col>
          <f7-col width="100">
            <f7-block-title>
              {{ $t('DxEmployeeTable_Title') }}:
            </f7-block-title>
            <DxViewWorkOrderEmployeeTable
              :employees-array="actualWorkOrder.WorkersDetailTab"
            />
          </f7-col>
          <f7-col width="100">
            <f7-block-title>
              {{ $t('DxProducts_Title') }}:
            </f7-block-title>
            <DxViewWorkOrderProducts
              :products="actualWorkOrder.products"
              :product-popup="actualWorkOrder.productPopup"
            />
            <f7-block-title>
              {{ $t('DxMachinery_Title') }}:
            </f7-block-title>
            <DxViewWorkOrderMachinaryDriverTable
              :items-availables="actualWorkOrder.items"
              :drivers-availables="actualWorkOrder.itemsDriversWorkOrder"
              :machinery="actualWorkOrder.machineryPopup"
            />
          </f7-col>
          <f7-col width="100">
            <f7-block-title>
              {{ $t('DxAttachment_Title') }}:
            </f7-block-title>
            <DxViewWorkOrderAttachmentMachinaryTable
              :types="actualWorkOrder.Attachments"
            />
          </f7-col>
          <f7-col width="100">
            <f7-block-title>
              {{ $t('DxTools_Title') }}:
            </f7-block-title>
            <DxViewWorkOrderToolsTable
              :select-tool="typeof actualWorkOrder.toolPopup === 'undefined' ? actualWorkOrder.Tools : actualWorkOrder.toolPopup"
            />
          </f7-col>
          <f7-col
            v-if="showBinsPlanningForm"
            width="100"
          >
            <f7-block-title>
              {{ $t('DxNursery_Title') }}
            </f7-block-title>
            <DxBinsContentGridPlanning
              :allow-editting="false"
            />
          </f7-col>
        </f7-row>
      </div>
      <f7-block-footer
        class="content-action-botton"
      >
        <f7-button
          class="dx-btn-cancel margin-right"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          @click="goBackToWO"
        >
          {{ $t("Button_go_to_back") }}
        </f7-button>
      </f7-block-footer>
    </div>
  </f7-page>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import DxViewPlanningWorkOrder from '../components/ViewPostWorkOrder/DxViewPlanningWorkOrder.vue';
import DxViewWorkOrderAttachmentMachinaryTable from '../components/ViewPostWorkOrder/DxViewWorkOrderAttachmentMachinaryTable.vue';
import DxViewWorkOrderMachinaryDriverTable from '../components/ViewPostWorkOrder/DxViewWorkOrderMachinaryDriverTable.vue';
import DxViewWorkOrderToolsTable from '../components/ViewPostWorkOrder/DxViewWorkOrderToolsTable.vue';
import DxViewWorkOrderSelectSupervisor from '../components/ViewPostWorkOrder/DxViewWorkOrderSelectSupervisor.vue';
import DxViewWorkOrderProducts from '../components/ViewPostWorkOrder/DxViewWorkOrderProducts.vue';
import DxViewWorkOrderEmployeeTable from '../components/ViewPostWorkOrder/DxViewWorkOrderEmployeeTable.vue';
import DxBinsContentGridPlanning from '../components/greenhouse/DxBinsContentGridPlanning.vue';

import navbar from '../components/NavBar.vue';
import Api from '../services/Api';

export default {
  name: 'ViewPostWork',
  components: {
    DxViewPlanningWorkOrder,
    DxViewWorkOrderAttachmentMachinaryTable,
    DxViewWorkOrderMachinaryDriverTable,
    DxViewWorkOrderSelectSupervisor,
    DxViewWorkOrderToolsTable,
    DxViewWorkOrderProducts,
    DxViewWorkOrderEmployeeTable,
    DxBinsContentGridPlanning,
    navbar,
  },
  props: {
    fullNameFilter: { type: String, default: '' },
    limitFilter: { type: Number, default: 9999 },
    pageFilter: { type: Number, default: 0 },
  },
  data() {
    return {
      loaded: false,
      itemsAvailables: [],
      driversAvailables: [],
      attachmentList: [],
      typesAttachmentList: [],
      typesMachinary: [],
      supervisorsAvailables: [],
      actualDate: moment().format('MM/DD/YYYY'),
    };
  },
  computed: {
    ...mapState('Employee', ['drivers']),
    ...mapState('PlanningManager', ['activitySelected', 'taskSelected', 'dateSelected', 'workingArea',
      'actualLocationsSelected', 'performanceUnit', 'performanceCount', 'actualWorkOrder', 'employees',
      'items', 'attachments', 'tools', 'groupedSelected', 'shiftValue', 'groupName', 'showProducts', 'keyName']),
    ...mapState('TaskManager', ['totalAreaSelected']),
    ...mapState('Prescription', ['prescription']),
    ...mapGetters('greenhouse', ['showBinsPlanningForm']),
  },
  beforeDestroy() {
    this.setTotalAreaSelected(0);
    this.setNewPrescription();
  },
  async beforeMount() {
    try {
      this.$f7.preloader.show();
      this.setNewPrescription();
      // this.initStore();
      // Cargamos los supervisores y el arbol de localizaciones que necesitan los supervisores
      if (this.actualWorkOrder.Supervisor !== '') {
        this.supervisorsAvailables.push({
          Supervisor: this.actualWorkOrder.Supervisor,
        });
      }
      this.driversAvailables = this.getEmployeesDriver();
    } catch (error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    async getEmployeesDriver() {
      if (this.drivers.length === 0) {
        const xhr = await Api.getEmployees();
        const employees = JSON.parse(xhr.response).data;
        return employees.filter((employee) => employee.driver === true);
      }
      return this.drivers;
    },
    async loadLocationsTree() {
      const xhr = await Api.getLocationsTree();
      return JSON.parse(xhr.response);
    },
    goBackToWO() {
      this.$f7.views.main.router.navigate(`/${this.keyName}/`, { reloadCurrent: true });
    },
    async createPlanning() {
      this.goToPostWork(); // TODO: Habría que mostrar alguna notificación de que se creó correctamente
    },
    ...mapActions('TaskManager', ['setTotalAreaSelected']),
    ...mapActions('Prescription', ['setNewPrescription']),
  },
};
</script>
