<template>
  <f7-page>
    <navbar :text="$t('PlanningComponent_monthlyPlanning')" />
    <DxMonthlyPlanning />
    <f7-block-footer
      class="content-action-botton"
    >
      <f7-button
        class="dx-btn-cancel margin-right"
        raised
        type="default"
        text-color="grey"
        styling-mode="outlined"
        @click="goBackToAnnualBudget"
      >
        {{ $t("Button_go_to_back") }}
      </f7-button>
    </f7-block-footer>
  </f7-page>
</template>

<script>

import DxMonthlyPlanning from '../../components/DxMonthlyPlanning.vue';
import navbar from '../../components/NavBar.vue';

export default {
  components: {
    DxMonthlyPlanning,
    navbar,
  },
  methods: {
    goBackToAnnualBudget() {
      this.$f7.views.main.router.navigate('/annualBudgets/', { reloadCurrent: true });
    },
  },
};
</script>

<style>

</style>
