<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-worker main-dx-worker"
  >
    <CalendarInitEndDate :key-name="keyName" />
    <DxDataGrid
      id="worker-grid"
      :data-source="workerFormated"
      :remote-operations="true"
      :column-min-width="90"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxWorker"
      />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxHeaderFilter :visible="true" />
      <DxColumnFixing :enabled="true" />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="date"
        :caption="$t('DxWorker_date_caption')"
        :allow-header-filtering="false"
        alignment="left"
        :width="100"
      />
      <DxColumn
        data-field="workerName"
        name="workerName"
        :caption="$t('DxWorker_workerName_caption')"
        :allow-header-filtering="false"
      />

      <DxColumn
        data-field="rut"
        caption="RUT"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="typeWorker"
        :caption="$t('DxWorker_typeWorker_caption')"
        :allow-header-filtering="true"
        alignment="left"
        :calculate-display-value="formattingTypeWorker"
      />
      <DxColumn
        data-field="shortId"
        :caption="$t('Reporting_DxWorkOrder_short_id')"
        :allow-header-filtering="false"
        alignment="left"
        css-class="bold"
      />
      <DxColumn
        data-field="responsible"
        :caption="$t('DxWorker_supervisor_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="taskCode"
        :caption="$t('DxWorker_codeTask_caption')"
        :allow-header-filtering="false"
        alignment="right"
      />
      <DxColumn
        data-field="task"
        :caption="$t('DxWorker_task_caption')"
        :allow-header-filtering="true"
        alignment="left"
        css-class="bold"
      />
      <DxColumn
        data-field="workingArea"
        data-type="number"
        :caption="$t('DxWorker_workingArea_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="ha"
        :caption="`${$t('DxWorker_performanceUnit_caption')}(${$t('DxWorker_workingArea_caption')})`"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="locations"
        :caption="$t('DxWorker_territorialUnit_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="variety"
        :caption="$t('DxWorker_variety_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="performanceCount"
        data-type="number"
        :caption="$t('DxWorker_productivity_caption')"
        :allow-header-filtering="false"
        alignment="right"
      />
      <DxColumn
        data-field="performanceUnit"
        :caption="`${$t('DxWorker_performanceUnitProductivity_caption')}(${$t('DxWorker_productivity_caption')})`"
        :allow-header-filtering="false"
        alignment="left"
        :calculate-display-value="translateUnit"
      />
      <DxColumn
        data-field="prescription"
        :caption="$t('DxWorker_prescription_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="productsUsed"
        data-type="number"
        :caption="$t('DxWorker_productsUsed_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="checkIn"
        :caption="$t('DxWorker_checkIn_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="checkOut"
        :caption="$t('DxWorker_checkOut_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="workingTime"
        :caption="$t('DxWorker_workingTime_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="machinery"
        :caption="$t('DxWorker_machineId_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="attachment"
        :caption="$t('DxWorker_implementoId_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="comment"
        :caption="$t('DxWorker_notes_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxScrolling column-rendering-mode="virtual" />
    </DxDataGrid>
    <div>
      <DxSideBySideBar :reporting-worker="tasks" />
    </div>
    <div>
      <DxSideBySideBarDayWorked :reporting-worker="tasks" />
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxScrolling,
  DxColumnFixing,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import moment from 'moment';
import Api from '../../services/Api';
import CalendarInitEndDate from '../CalendarInitEndDate.vue';
import DxSideBySideBar from '../chartPlanned/DxSideBySideBar.vue';
import DxSideBySideBarDayWorked from '../chartPlanned/DxSideBySideBarDayWorked.vue';

export default {
  name: 'DxWorker',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
    DxStateStoring,
    CalendarInitEndDate,
    DxPager,
    DxSelection,
    DxSideBySideBar,
    DxSideBySideBarDayWorked,
  },
  data() {
    return {
      loaded: false,
      workerFormated: [],
      actualDate: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
      keyName: 'reportingWorker',
      pageSizes: [5, 10, 20],
      tasks: [],
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
    ...mapGetters('greenhouse', ['getLocationNameLevelTwoByChildId']),
  },
  async created() {
    this.$f7.preloader.show();
    try {
      await this.fetchLocationTree();
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      const xhr = await Api.getWorkersSummary(checkedInitEndDate.init, checkedInitEndDate.end);
      this.tasks = JSON.parse(xhr.response).data;
      for (const task of this.tasks) {
        for (const worker of task.workers) {
          const performanceUnit = worker.unit;
          let performanceCount = '';
          if (typeof worker === 'undefined' || performanceUnit === '') {
            performanceCount = '';
          } else {
            performanceCount = worker.performance;
          }
          this.workerFormated.push({
            id: task.id,
            date: task.date,
            workerName: `${worker.name} ${worker.surname}`,
            rut: worker.code,
            typeWorker: worker.type,
            shortId: task.short_id,
            responsible: task.responsible,
            taskCode: task.task_code,
            task: task.task,
            workingArea: worker.working_area,
            ha: 'ha',
            locations: this.getNamesByBdIds(task.locations),
            variety: task.variety,
            performanceCount,
            performanceUnit,
            prescription: task.prescription,
            productsUsed: task.products_used,
            checkIn: moment(worker.init_date).format('HH:mm'),
            checkOut: worker.end_date ? moment(worker.end_date).format('HH:mm') : '',
            workingTime: worker.working_time,
            group: task.group,
            machinery: worker.machinery,
            attachment: worker.attachment,
            comment: worker.comment,
          });
        }
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    ...mapActions('greenhouse', ['fetchLocationTree']),
    getNamesByBdIds(ids) {
      const names = [];
      for (const id of ids) {
        const newName = this.getLocationNameLevelTwoByChildId(id);
        if (names.indexOf(newName) === -1) {
          names.push(newName);
        }
      }
      return names;
    },
    formattingTypeWorker(rowData) {
      if (rowData.typeWorker === 'TEMPORARY') {
        return 'T';
      } if (rowData.typeWorker === 'PERMANENT') {
        return 'P';
      }
      return rowData.typeWorker;
    },
    translateUnit(row) {
      return this.$t(`${row.performanceUnit}`);
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('ReportingWorkers');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ReportingWorkers.xlsx');
          });
      });
      e.cancel = true;
    },
  },
};
</script>
<style scoped>
</style>
