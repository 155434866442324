<template>
  <div>
    <div
      class="content-devx content-dx-daily-planning no-shadow no-margin"
    >
      <DxDataGrid
        id="postworkattachmentMachinaryTable"
        ref="postworkattachmentMachinaryTable"
        :data-source="attachments.values"
        :column-min-width="70"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :column-auto-width="true"
        :row-alternation-enabled="true"
        @row-inserted="onInsertedRow"
        @row-updated="onChangeRow"
        @row-removed="onRemovedRow"
      >
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="DxPostWorkAttachmentMachineryTable"
        />
        <DxExport
          :enabled="false"
        />
        <DxPaging :page-size="18" />
        <DxEditing
          :allow-updating="updating"
          :allow-deleting="deleting"
          :allow-adding="adding"
          mode="row"
        />
        <DxSearchPanel
          :visible="true"
          :width="400"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          data-field="type"
          alignment="left"
          :caption="$t('DxAttachment_dxColumn_TypeAttachment')"
          :set-cell-value="actualActiveType"
        >
          <DxLookup
            :data-source="types"
            display-expr="name"
            value-expr="id"
          />
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="vehicle"
          alignment="left"
          name="vehicle"
          :caption="$t('DxAttachmentMachinary_dxColumn_Machinary')"
          :set-cell-value="actualActiveTractor"
        >
          <DxLookup
            :data-source="allTractors"
            display-expr="name"
            value-expr="id"
          />
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="attachment"
          alignment="left"
          name="attachment"
          :caption="$t('DxAttachmentMachinary_dxColumn_Attachment')"
          :set-cell-value="actualActiveAttachment"
        >
          <DxLookup
            :data-source="filteredAttachment"
            display-expr="name"
            value-expr="id"
          />
          <DxRequiredRule />
        </DxColumn>
      </DxDataGrid>
    </div>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxSearchPanel,
  DxExport,
  DxRequiredRule,
  DxLookup,
  DxStateStoring,
} from 'devextreme-vue/data-grid';
import * as overlay from 'devextreme/ui/overlay';
import EventBus from '../../js/event-bus';

export default {
  name: 'DxAttachmentMachinaryTable',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxSearchPanel,
    DxExport,
    DxRequiredRule,
    DxLookup,
    DxStateStoring,
  },
  props: {
    attachmentList: { type: Array, default: () => [] },
    itemsPlanning: { type: Array, default: () => [] },
    machinaryAvailables: { type: Array, default: () => [] },
    updating: { type: Boolean, default: () => true },
    deleting: { type: Boolean, default: () => false },
    adding: { type: Boolean, default: () => false },
    types: { type: Array, default: () => [] },
    actualWorkOrder: { type: Object, default: () => {} },
    isTaskOrderCompleted: { type: Boolean, default: false },
    typesMachinery: { type: Array, default: () => [] },
  },
  data() {
    return {
      allAttachments: [],
      allTractors: [],
      actualActiveAttachment(rowData, value) {
        this.defaultSetCellValue(rowData, value);
      },
      actualActiveTractor(rowData, value) {
        rowData.attachment = null;
        this.defaultSetCellValue(rowData, value);
      },
      actualActiveType(rowData, value) {
        rowData.attachment = null;
        rowData.vehicle = null;
        this.defaultSetCellValue(rowData, value);
      },
      attachementsVehiclesFormatted: [],
    };
  },
  computed: {
    ...mapState('PlanningManager', ['attachments', 'items']),
  },
  beforeMount() {
    overlay.baseZIndex(9999);
    // formateamos para obtener el type.
    if (this.isTaskOrderCompleted) {
      for (const attachment of this.actualWorkOrder.attachments_vehicles) {
        if (attachment.vehicle !== null) {
          this.attachementsVehiclesFormatted.push({
            vehicle: attachment.vehicle,
            attachment: attachment.attachment,
            type: attachment.attachment_data.item.subfamily,
          });
        }
      }
      this.setAttachments(this.attachementsVehiclesFormatted);
    }
    this.allTractors = [];
    const arrAttachments = [];
    for (let i = 0; i < this.itemsPlanning.length; i += 1) {
      if (this.itemsPlanning[i].count !== undefined) {
        for (let j = 0; j < this.itemsPlanning[i].count; j += 1) {
          const numElement = j + 1;
          arrAttachments.push({ type: `${this.itemsPlanning[i].type} ${numElement}` });
        }
      } else {
        let vehiculo = '';
        if (this.itemsPlanning[i].vehicle !== null) {
          vehiculo = this.itemsPlanning[i].vehicle;
        }
        // Modo editar workorder
        if (!this.isTaskOrderCompleted) {
          arrAttachments.push({
            type: `${this.itemsPlanning[i].attachment_data.item.subfamily}`,
            vehicle: `${vehiculo}`,
            attachment: `${this.itemsPlanning[i].attachment}`,
          });
        }
      }
      this.setAttachments(arrAttachments);
    }
    // Seteamos un listado con todos los aperos, desde el que filtraremos
    for (let i = 0; i < this.attachmentList.length; i += 1) {
      const idSubfamily = this.attachmentList[i].id;
      for (let j = 0; j < this.attachmentList[i].items.length; j += 1) {
        const item = this.attachmentList[i].items[j];
        const idItem = item.id;
        let nameItem = item.description;
        for (let k = 0; k < item.properties.length; k += 1) {
          if (item.properties[k].key === 'internal_code') nameItem = `${nameItem} ${item.properties[k].value}`;
        }
        this.allAttachments.push({ id: idItem, name: nameItem, idParent: idSubfamily });
      }
    }
  },
  // Antes setMachinery estaba en el beforeMount pero las maquinarías no se cargaban.
  mounted() {
    // Seteamos un listado con los tractores
    this.setMachinery();
    EventBus.$on('setMachinery', this.setMachinery);
  },
  beforeDestroy() {
    EventBus.$off('setMachinery');
  },
  methods: {
    // SET DATA SOURCE TO PRODUCTS VUEX
    onChangeRow() {
      const arrayItems = this.$refs.postworkattachmentMachinaryTable.instance.getDataSource().items();
      const newItems = this.attachments;
      newItems.values = [];
      for (let i = 0; i < arrayItems.length; i += 1) {
        newItems.values.push({ type: arrayItems[i].type, attachment: arrayItems[i].attachment, vehicle: arrayItems[i].vehicle });
      }
      this.setAttachments(newItems.values);
    },
    onInsertedRow() {
      const newItems = this.attachments;
      this.setAttachments(newItems.values);
    },
    filteredAttachment(e) {
      try {
        if (e.values !== undefined) {
          const listaFiltrado = this.allAttachments.filter(
            (elemento) => elemento.idParent === e.values[0],
          );
          return listaFiltrado;
        }
        return this.allAttachments;
      } catch (error) {
        return [{ id: '', name: '' }];
      }
    },
    onRemovedRow() {
      const newItems = this.attachments;
      this.setAttachments(newItems.values);
    },
    // setMachinery() {
    //   this.allTractors = [];
    //   for (const typeMachinery of this.machinaryAvailables) {
    //     for (const vehicle of typeMachinery.items) {
    //       for (const item of this.items.values) {
    //         if (!this.isTaskOrderCompleted) {
    //           if (item.item_id === vehicle.id) {
    //             this.allTractors.push({ id: vehicle.id, name: `${vehicle.description} ${this.$helpers.getProperty(vehicle.properties, 'internal_code')}` });
    //           }
    //         } else if (this.attachementsVehiclesFormatted.vehichle === vehicle.id) {
    //           this.allTractors.push({ id: vehicle.id, name: `${vehicle.description} ${this.$helpers.getProperty(vehicle.properties, 'internal_code')}` });
    //         }
    //       }
    //     }
    //   }
    //   this.clearRowIfNotExists();
    // },
    setMachinery() {
      this.allTractors = [];
      for (const typeMachinery of this.machinaryAvailables) {
        for (const vehicle of typeMachinery.items) {
          for (const item of this.items.values) {
            if (item.item_id === vehicle.id) {
              this.allTractors.push({ id: vehicle.id, name: `${vehicle.description} ${this.$helpers.getProperty(vehicle.properties, 'internal_code')}` });
            }
          }
        }
      }
      if (!this.isTaskOrderCompleted) {
        this.clearRowIfNotExists();
      }
    },
    clearRowIfNotExists() {
      const clearedAttachment = [];
      for (const item of this.items.values) {
        for (const attachment of this.attachments.values) {
          if (item.item_id === attachment.vehicle) {
            clearedAttachment.push(attachment);
          }
        }
      }
      this.setAttachments(clearedAttachment);
    },
    getProperty(properties, key) {
      for (const property of properties) {
        if (property.key === key) {
          return property.value;
        }
      }
      return '';
    },
    ...mapActions('PlanningManager', ['setAttachments']),
  },
};
</script>
