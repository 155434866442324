<template>
  <DxDataGrid
    id="machinary-tab"
    ref="binsTable"
    :data-source="nursery"
    :column-min-width="70"
    :allow-column-reordering="true"
    :show-borders="true"
    :show-column-lines="true"
    :show-row-lines="false"
    :row-alternation-enabled="false"
    :column-auto-width="true"
  >
    <DxColumn
      data-field="code"
      name="code"
      :caption="$t('DxDetailTabBins_dxColumn_Code')"
    />
    <DxColumn
      v-if="showLocationName"
      data-field="locationName"
      name="locationName"
      :caption="$t('DxDetailTabBins_dxColumn_LastLevel')"
    />
    <DxColumn
      data-field="variety"
      name="variety"
      :caption="$t('DxDetailTabBins_dxColumn_Variety')"
    />
    <DxColumn
      data-field="category"
      name="category"
      :caption="$t('DxDetailTabBins_dxColumn_Category')"
    />
    <DxColumn
      data-field="class_type"
      name="class_type"
      :caption="$t('DxDetailTabBins_dxColumn_Classification')"
    />
    <DxColumn
      data-field="count"
      name="count"
      data-type="number"
      :caption="$t('DxDetailTabBins_dxColumn_NumPlants')"
    />
    <DxColumn
      data-field="egreso"
      name="egreso"
      :caption="$t('DxBinsContentGrid_egress_caption')"
    />
  </DxDataGrid>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
} from 'devextreme-vue/data-grid';

export default {
  components: {
    DxDataGrid,
    DxColumn,
  },
  props: {
    nursery: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    showLocationName() {
      if (this.nursery
        && this.nursery.length > 0
        && this.nursery[0].locationName
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
  },
};
</script>
<style scoped>
.backgroundColor{
  background-color:white;
}
</style>
