<template>
  <div class="content-location">
    <DxForm>
      <DxSimpleItem
        v-if="showGrouped"
        css-class="content-location__grouped"
        :data-field="$t('PlanningComponent_grouped_caption')"
        name="grouped"
        :caption="$t('PlanningComponent_grouped_caption')"
        editor-type="dxCheckBox"
        :editor-options="{
          value: groupedSelected,
          onValueChanged: groupedSelectedEvent,
          readOnly: isTaskOrderCompleted,
        }"
      />
    </DxForm>
    <DxTreeList
      id="locationsTree"
      ref="treeList"
      class="tree-planning__treelist"
      :style="`max-height: ${maxHeight}px`"
      :data-source="locationsTreeFormated"
      :show-row-lines="true"
      :show-borders="true"
      :column-auto-width="true"
      :expanded-row-keys="expandedRowKeys"
      :selected-row-keys.sync="selectedRowKeys"
      key-expr="ID"
      parent-id-expr="HEAD_ID"
      :disabled="isTaskOrderCompleted"
      @selection-changed="saveLocations"
    >
      <DxSelection
        v-if="editable"
        :recursive="recursive"
        :mode="selectionMode"
      />
      <DxColumn
        data-field="location"
      />
    </DxTreeList>
    <f7-block v-if="isNurseryURL">
      <p>{{ $t('Nursery_OnlyOneLastLevelMessage') }}</p>
    </f7-block>
  </div>
</template>
<script>
import { DxTreeList, DxSelection, DxColumn } from 'devextreme-vue/tree-list';
import { mapState, mapActions, mapGetters } from 'vuex';
import {
  DxForm,
  DxSimpleItem,
} from 'devextreme-vue/form';
import EventBus from '../js/event-bus';

export default {
  components: {
    DxTreeList,
    DxSelection,
    DxColumn,
    DxForm,
    DxSimpleItem,
  },
  props: {
    planning: { type: Object, default: null },
    locationSelected: { type: Array, default: () => [] },
    locationsTree: { type: Array, default: () => [] },
    showToDefault: { type: Boolean, default: true },
    showExpanded: { type: Boolean, default: true },
    recursive: { type: Boolean, default: true },
    editable: { type: Boolean, default: true },
    showGrouped: { type: Boolean, default: true },
    showSummary: { type: Boolean, default: true },
    maxHeight: { type: Number, default: 600 },
    selectionMode: { type: String, default: 'multiple' },
    onlyLevel0: { type: Boolean, default: false },
    monitoringPoints: { type: Boolean, default: false },
    isTaskOrderCompleted: { type: Boolean, default: false },
    locationsCompleted: { type: Array, default: () => [] },
  },
  data() {
    return {
      locations: [],
      selectedRowKeys: [],
      expandedRowKeys: [],
      locationsSelected: [],
      locationsTreeFormated: [],
      locationsAreas: [],
      level1DataArea: [],
      currentLevel1Name: '',
      currentLevel1Area: 0,
      totalArea: 0,
      actualLocationsSelectedLevel0: [],
      actualHeadLevelLocationSelected: [],
      actualLocationsSelectedNoRecursive: [],
      locationsCompletedId: [],
    };
  },
  computed: {
    ...mapState('PlanningManager', ['actualLocationsSelected', 'actualLocationsTreeFormated', 'groupedSelected']),
    ...mapGetters('greenhouse', ['locationTreeByName']),
    showAreasSummary() {
      return this.totalArea !== 0 && this.showSummary;
    },
    currentURL() {
      return this.$f7route.url;
    },
    isNurseryURL() {
      return this.currentURL === '/bins/create/'
          || this.currentURL === '/bins/edit/'
          || this.currentURL === '/bins/move/'
          || this.currentURL === '/bare-roots/create/'
          || this.currentURL === '/bare-roots/edit/';
    },
  },
  beforeMount() {
    if (this.locationsCompleted.length > 0) {
      this.setLocationsCompletedId(this.locationsCompleted);
    }
    EventBus.$on('clear-tree-planning-selected-location', this.clearTreePlanning);
    EventBus.$on('level-zero-location-changed', this.updateTreeList);
  },
  mounted() {
    if (this.planning != null && ((typeof this.planning.locations) !== 'undefined')) {
      this.locations = this.planning.locations;
    } else {
      this.locations = this.locationSelected;
    }
    this.setActualLocationsSelected([]);
    for (const item of this.locationsTree) {
      this.setLocationTreeFormat(item);
      this.recursiveAddChildrens(item.children);
    }
    if (this.onlyLevel0) {
      this.locationsTreeFormated = this.locationsTreeFormated.filter(
        (location) => location.level === 0,
      );
    }
    this.setActualLocationsTreeFormated(this.locationsTreeFormated);
    if (this.locations.length !== 0) {
      this.locationsSelected = this.locations;
      this.setSelectedRowKeys();
    }
  },
  beforeDestroy() {
    EventBus.$off('clear-tree-planning-selected-location');
    EventBus.$off('level-zero-location-changed');
  },
  methods: {
    // Añado el método groupSelected de la clase PlanningForm para el campo group.
    groupedSelectedEvent(e) {
      this.setGroupedSelected(e.value);
      this.actualGrouped = e.value;
    },
    updateTreeList() {
      this.locationsTreeFormated = [];
      for (const item of this.locationTreeByName) {
        this.setLocationTreeFormat(item);
        this.recursiveAddChildrens(item.children);
      }
      this.setActualLocationsTreeFormated(this.locationsTreeFormated);
      if (this.locations.length !== 0) {
        this.locationsSelected = this.locations;
        this.setSelectedRowKeys();
      }
    },
    clearTreePlanning() {
      this.locationsSelected = [];
      this.setSelectedRowKeys();
      this.setActualLocationsSelected([]);
      this.expandedRowKeys = [];
    },
    setExpandedRowKeys(location) {
      let parentLocation = location;
      while (parentLocation && parentLocation.HEAD_ID !== null) {
        if (this.expandedRowKeys.indexOf(parentLocation.ID) === -1) {
          this.expandedRowKeys.push(parentLocation.ID);
        }
        parentLocation = this.locationsTreeFormated[parentLocation.HEAD_ID - 1];
      }
      if (parentLocation && this.expandedRowKeys.indexOf(parentLocation.ID) === -1) {
        this.expandedRowKeys.push(parentLocation.ID);
      }
    },
    recursiveSelectedKeys(location) {
      if (this.locations.indexOf(location.bd_id) > -1) {
        if (this.selectedRowKeys.indexOf(location.ID) === -1) {
          this.selectedRowKeys.push(location.ID);
          if (this.showExpanded) {
            this.setExpandedRowKeys(location);
          }
        }
      }
      for (let i = 0; i < location.children.length; i += 1) {
        this.recursiveSelectedKeys(location.children[i]);
      }
    },
    setSelectedRowKeys() {
      this.selectedRowKeys = [];
      for (let i = 0; i < this.locationsTreeFormated.length; i += 1) {
        this.recursiveSelectedKeys(this.locationsTreeFormated[i]);
      }
    },
    recursiveAddChildrens(childrens) {
      for (let i = 0; i < childrens.length; i += 1) {
        this.setLocationTreeFormat(childrens[i]);
        this.recursiveAddChildrens(childrens[i].children);
      }
    },
    setLocationTreeFormat(item) {
      let varietyCode = '';
      if (typeof item.properties.variety_code !== 'undefined' && item.properties.variety_code !== '') {
        varietyCode = `-${item.properties.variety_code}`;
      }
      this.locationsTreeFormated.push({
        location: `${item.name} ${varietyCode}- ${item.area.toFixed(2)} ha`,
        bd_id: item.bd_id,
        ID: item.ID,
        HEAD_ID: item.HEAD_ID,
        children: item.children,
        level: item.level,
        name: item.name,
        area: item.area,
      });
    },
    addLocationCompleted(location) {
      if (!this.locationsCompletedId.includes(location)) {
        this.locationsCompletedId.push(location);
      }
    },
    /**
     * Comprueba si todos sus hijos están seleccionados
     */
    isFullChildrensSelected(selectedLocations, childrens) {
      for (const children of childrens) {
        if (!selectedLocations.includes(children)) {
          return false;
        }
      }
      return true;
    },
    /**
     * Obtiene las hojas para ese nodo
     */
    getLastChildrens(lastChildrens, location) {
      if (location.children.length === 0) {
        lastChildrens.push(location.bd_id);
      } else {
        for (const children of location.children) {
          this.getLastChildrens(lastChildrens, children);
        }
      }
    },
    /**
     * Obtenemos los nodos que están completamente seleccionados, dando prioridad
     * a los padres, es decir, los que tienen un nivel jerárquico más importante (0 > 1 > 2...)
     */
    fetchFullNodeSelected(selectedLocations, locations) {
      const fullNodes = {};
      for (const location of locations) {
        const lastChildrens = [];
        this.getLastChildrens(lastChildrens, location);
        fullNodes[`${location.bd_id}`] = { lastChildrens, childrens: location.children };
      }
      for (const location in fullNodes) {
        if (Object.hasOwnProperty.call(fullNodes, location)) {
          const { lastChildrens } = fullNodes[location];
          if (this.isFullChildrensSelected(selectedLocations, lastChildrens)) {
            this.addLocationCompleted(location);
          } else {
            this.fetchFullNodeSelected(selectedLocations, fullNodes[location].childrens);
          }
        }
      }
    },
    saveLocations(selection) {
      this.locationsCompletedId = [];
      this.locationsSelected = [];
      this.locationsAreas = [];
      this.totalArea = 0;
      this.currentLevel1Name = '';
      this.currentLevel1Area = 0;
      this.level1DataArea = [];
      for (let i = 0; i < selection.selectedRowsData.length; i += 1) {
        this.recursiveLocationsSelected(selection.selectedRowsData[i]);
      }
      this.locationsAreas = [...this.locationsSelected];
      this.setTotalAreasLevel1();
      const previousLocation = [...this.actualLocationsSelected];
      this.setActualLocationsSelected(this.locationsSelected);
      this.fetchFullNodeSelected(this.locationsSelected, this.locationsTree);
      this.setLocationsCompletedId(this.locationsCompletedId);
      if (
        this.currentURL === '/createPlanning/'
        || this.currentURL === '/editPlanning/'
        || this.currentURL === '/editOrder/workOrders/'
        || this.currentURL === '/createOrder/'
        || this.currentURL === '/createPostWork/'
      ) {
        if (this.actualLocationsSelected.length > 0) {
          this.getBinsContentsFilteredByLocation();
        }
        this.updateBinsContentPlanned([]);
      }
      if (previousLocation.length === 0) {
        EventBus.$emit('DxTreePlanningMounted');
      }
      this.setTotalAreaSelected(this.totalArea.toFixed(2));
      EventBus.$emit('changeTotalAreaSelected');
      // FUNCIONALIDAD AÑADIDA PARA FORMULARIO EMPLEADOS - GUARDA LOS FARM DE NIVEL SUPERIOR ASIGNADOS AL EMPLEADO
      this.actualLocationsSelectedLevel0 = [];
      this.actualHeadLevelLocationSelected = [];
      this.actualLocationsSelectedNoRecursive = [];
      for (const location of selection.selectedRowsData) {
        this.actualLocationsSelectedLevel0.push(location.bd_id);
        this.actualHeadLevelLocationSelected.push(location.bd_id);
        this.actualLocationsSelectedNoRecursive.push(location.bd_id);
      }
      this.setActualLocationsSelectedLevel0(this.actualLocationsSelectedLevel0);
      this.setActualHeadLevelLocationSelected(this.actualHeadLevelLocationSelected);
      if (this.monitoringPoints) {
        if (this.selectedRowKeys.length > 1) {
          this.$f7.dialog.alert(this.$t('Point_location_validation'));
          this.selectedRowKeys = this.selectedRowKeys.splice(0, 1);
        } else {
          this.setActualLocationsSelectedNoRecursive(this.actualLocationsSelectedNoRecursive);
        }
      }
    },
    setTotalAreasLevel1() {
      for (let i = 0; i < this.locationsTreeFormated.length; i += 1) {
        if (this.locationsAreas.length === 0) {
          // Si no hay ids en el array de seleccionados, no recorremos el bucle
          break;
        } else if (this.locationsTreeFormated[i].level === 1) {
          if (this.currentLevel1Name !== '' && this.currentLevel1Area !== 0) {
            const item = { name: this.currentLevel1Name, area: this.currentLevel1Area };
            this.level1DataArea.push(item);
          }
          // Solo comprobamos los elementos con nivel === 1, sus hijos los comprobamos dentro
          this.currentLevel1Name = this.locationsTreeFormated[i].name;
          this.currentLevel1Area = 0;
          this.recursiveGetAreasChildrens(this.locationsTreeFormated[i]);
        }
      }
      if (this.currentLevel1Name !== '' && this.currentLevel1Area !== 0) {
        const item = { name: this.currentLevel1Name, area: this.currentLevel1Area };
        this.level1DataArea.push(item);
      }
    },
    recursiveGetAreasChildrens(items) {
      if (items.children.length === 0) {
        // Si el nivel no tiene hijos, comprobamos si su id está en el array
        const check = this.locationsAreas.indexOf(items.bd_id);
        if (check !== -1) {
          // Está en el array, lo guardamos para pintar su area y sacamos el id del array.
          this.currentLevel1Area += items.area;
          this.locationsAreas.splice(check, 1); // Elimino el id del array
        }
      } else {
        // El nivel tiene hijos
        for (let j = 0; j < items.children.length; j += 1) {
          this.recursiveGetAreasChildrens(items.children[j]);
        }
      }
    },
    recursiveLocationsSelected(locationSelected) {
      if (locationSelected.children.length === 0) {
        this.totalArea += locationSelected.area;
        this.addLocationsSelected(locationSelected.bd_id);
      } else {
        for (let i = 0; i < locationSelected.children.length; i += 1) {
          this.recursiveLocationsSelected(locationSelected.children[i]);
        }
      }
    },
    addLocationsSelected(bdId) {
      if (this.locationsSelected.indexOf(bdId) === -1) {
        this.locationsSelected.push(bdId);
      }
    },
    ...mapActions('TaskManager', ['setTotalAreaSelected']),
    ...mapActions('PlanningManager', ['setActualLocationsSelected', 'setActualLocationsTreeFormated', 'setGroupedSelected', 'setActualLocationsSelectedLevel0', 'setActualHeadLevelLocationSelected', 'setActualLocationsSelectedNoRecursive', 'setLocationsCompletedId']),
    ...mapActions('greenhouse', [
      'getBinsContentsFilteredByLocation',
      'updateBinsContentPlanned',
    ]),
  },
};
</script>
<style lang="scss" scoped>
#employees {
    max-height: 440px;
}

.options {
    padding: 20px;
    margin-top: 20px;
    background-color: rgba(191, 191, 191, 0.15);
}

.caption {
    font-size: 18px;
    font-weight: 500;
}

.option {
    margin-top: 10px;
}
</style>
