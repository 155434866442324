<template>
  <f7-page>
    <navbar :text="$t('LeftPanel_subtitle_reportingHarvest_ot')" />
    <f7-row>
      <f7-col width="45">
        <DxHarvestByVarieties />
      </f7-col>
      <f7-col width="55">
        <f7-row>
          <f7-col>
            <DxHarvestByDays />
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col>
            <DxGraphLinePoint />
          </f7-col>
        </f7-row>
      </f7-col>
    </f7-row>
  </f7-page>
</template>
<script>

import navbar from '../../components/NavBar.vue';
import DxHarvestByDays from '../../components/reporting/reportingHarvest/DxHarvestByDays.vue';
import DxHarvestByVarieties from '../../components/reporting/reportingHarvest/DxHarvestByVarieties.vue';
import DxGraphLinePoint from '../../components/reporting/reportingHarvest/DxGraphLinePoint.vue';

export default {
  components: {
    navbar,
    DxHarvestByDays,
    DxHarvestByVarieties,
    DxGraphLinePoint,
  },
};
</script>

<style>

</style>
