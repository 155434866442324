<template>
  <div>
    <div
      class="content-devx content-dx-daily-planning no-shadow no-margin"
    >
      <DxForm
        id="form"
        :col-count="1"
      >
        <DxSimpleItem
          :editor-options="{
            items: supervisorsAvailables,
            value: isTaskOrderCompleted ? actualWorkOrder.responsible : responsible,
            searchEnabled: true,
            searchTimeout: searchTimeoutOption,
            minSearchLength: minSearchLengthOption,
            showDataBeforeSearch: showDataBeforeSearchOption,
            onValueChanged: onSupervisorChange,
            readOnly: readOnly,
            displayExpr: 'fullname',
            valueExpr:'id',
          }"
          editor-type="dxSelectBox"
          :caption="$t('SelectSupervisor_supervisor_caption')"
        >
          <DxRequiredRule :message="$t('DxSelectSupervisor')" />
        </DxSimpleItem>
        <DxSimpleItem
          editor-type="dxTextArea"
          :editor-options="{
            placeholder: $t('DxInformationPrescription_notes_caption'),
            value: isTaskOrderCompleted ? actualWorkOrder.comment : additionalInfo,
            onValueChanged: additionalInfoEvent,
            maxLength: 256,
          }"
        />
      </DxForm>
    </div>
  </div>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import * as overlay from 'devextreme/ui/overlay';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'DxSelectSupervisor',
  components: {
    DxForm,
    DxSimpleItem,
    DxRequiredRule,
  },
  props: {
    supervisorsAvailables: { type: Array, default: () => [] },
    readOnly: { type: Boolean, default: false },
    actualAdditionalInfo: { type: String, default: '' },
    actualWorkOrder: { type: Object, default: () => {} },
    isTaskOrderCompleted: { type: Boolean, default: false },
  },
  data() {
    return {
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      nameSupervisors: [],
      additionalInfo: '',
      responsible: '',
    };
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.additionalInfo = this.actualAdditionalInfo;
    this.setAdditionalInfoWorkOrder(this.additionalInfo);
    // seteamos el dato cuando sea edit Work Order.
    if (this.isTaskOrderCompleted) {
      this.setSupervisorsLocation(this.actualWorkOrder.responsible);
    }
  },
  methods: {
    onSupervisorChange(e) {
      this.setSupervisorsLocation(e.value);
      this.responsible = e.value;
    },
    additionalInfoEvent(e) {
      const newValue = (e.value) ?? 0;
      this.setAdditionalInfoWorkOrder(newValue);
      this.additionalInfo = newValue;
    },
    ...mapActions('PlanningManager', ['setSupervisorsLocation', 'setAdditionalInfoWorkOrder']),
  },
};
</script>
<style>
.status-icon {
  height: 16px;
  width: 16px;
  display: inline-block;
  margin-right: 8px;
}
.middle {
  vertical-align: middle;
}
</style>
