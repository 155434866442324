<template>
  <div
    v-if="loaded"
    class="content-devx main-dx-planning-component no-shadow"
  >
    <div id="form-container">
      <f7-row>
        <f7-col
          width="25"
          medium="30"
        >
          <DxTreePlanning
            v-if="!isTaskOrderCompleted"
            :locations-tree="locationsTree"
            :planning="actualDailyPlanning"
            :show-grouped="showTables"
          />
          <DxTreePlanning
            v-else
            :locations-tree="locationsTree"
            :location-selected="actualWorkOrder.location"
            :show-grouped="false"
            :is-task-order-completed="isTaskOrderCompleted"
          />
        </f7-col>
        <f7-col
          width="75"
          medium="70"
        >
          <DxPlanningForm
            :planning="actualDailyPlanning"
            :show-tables="showTables"
            :data-form="{activityName: activitiesAvailables, taskName: availableTasks, workerTypes, itemTypes, performanceTypes }"
            :calendar-multiple="calendarMultiple"
            :max-date="maxDate"
            :distributed-visible="distributedVisible"
            :working-area-disabled="workingAreaDisabled"
            :machinery-availables="machineryAvailables"
            :tools-availables="toolsAvailables"

            :actual-work-order="actualWorkOrder"
            :is-task-order-completed="isTaskOrderCompleted"
          />
          <div v-if="showProducts && showTables">
            <f7-block-title>
              {{ $t('DxProducts_Title') }}:
            </f7-block-title>
            <DxPlanningProducts />
          </div>
          <div v-if="showBinsPlanningForm && isNurseryPlanningURL">
            <f7-block-title>
              {{ $t('DxNursery_Title') }}
            </f7-block-title>
            <DxBinsContentGridPlanning />
          </div>
        </f7-col>
      </f7-row>
    </div>
  </div>
</template>
<script>

import { mapState, mapActions, mapGetters } from 'vuex';
import * as overlay from 'devextreme/ui/overlay';
import Api from '../../services/Api';
import DxTreePlanning from '../DxTreePlanning.vue';
import DxPlanningForm from './DxPlanningForm.vue';
import DxPlanningProducts from './DxPlanningProducts.vue';
import DxBinsContentGridPlanning from '../greenhouse/DxBinsContentGridPlanning.vue';

export default {
  name: 'DxPlanningComponent',
  components: {
    DxTreePlanning,
    DxPlanningForm,
    DxPlanningProducts,
    DxBinsContentGridPlanning,
  },
  props: {
    showTables: { type: Boolean, default: true },
    calendarMultiple: { type: Boolean, default: true },
    maxDate: { type: String, default: '' },
    distributedVisible: { type: Boolean, default: true },
    workingAreaDisabled: { type: Boolean, default: false },
    machineryAvailables: { type: Array, default: () => [] },
    toolsAvailables: { type: Array, default: () => [] },
    actualWorkOrder: { type: Object, default: () => {} },
    isTaskOrderCompleted: { type: Boolean, default: false },
  },
  data() {
    return {
      loaded: false,
      locationsTree: [],
      activitiesAvailables: [],
      // tasksAvailables: [],
      itemTypes: [],
      workerTypes: [],
      performanceTypes: [],
    };
  },
  computed: {
    ...mapState('PlanningManager', ['actualDailyPlanning', 'showProducts']),
    ...mapGetters('greenhouse', ['showBinsPlanningForm']),
    ...mapState('greenhouse', ['availableTasks']),
    currentURL() {
      return this.$f7route.url;
    },
    isNurseryPlanningURL() {
      return this.currentURL === '/createPlanning/'
          || this.currentURL === '/editPlanning/'
          || this.currentURL === '/editOrder/workOrders/'
          || this.currentURL === '/createOrder/';
    },
  },
  async beforeMount() {
    this.setShowProducts(false);
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      this.locationsTree = await this.loadLocationsTree();
      this.activitiesAvailables = await this.loadActivitiesAvailables();
      if (this.availableTasks.length === 0) {
        const tasksAvailables = await this.loadTasksAvailables();
        this.updateAvailableTasks(tasksAvailables);
      }
      this.ItemsTypes = await this.loadItemsType();
    } catch (error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    async loadLocationsTree() {
      const xhr = await Api.getLocationsTree();
      return JSON.parse(xhr.response);
    },
    async loadActivitiesAvailables() {
      const xhr = await Api.getActivities();
      return JSON.parse(xhr.response);
    },
    async loadTasksAvailables() {
      const xhr = await Api.getTasksDefinition();
      return JSON.parse(xhr.response);
    },
    async loadItemsType() {
      const xhr = await Api.getItemsType();
      const items = JSON.parse(xhr.response);
      for (let i = 0; i < items.length; i += 1) {
        const item = items[i];
        if (item.family === 'ITEM_SUBFAMILY_VEHICLE') {
          this.itemTypes = this.itemTypes.concat(item.values);
        } else if (item.family === 'WORKER_TYPE') {
          this.workerTypes = this.workerTypes.concat(item.values);
        } else if (item.family === 'PERFORMANCE_UNITS') {
          this.performanceTypes = this.performanceTypes.concat(item.values);
        }
      }
    },
    ...mapActions('PlanningManager', ['setShowProducts']),
    ...mapActions('greenhouse', ['updateAvailableTasks']),
  },
};
</script>

<style scoped>
.long-title h3 {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}
</style>
