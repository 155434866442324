<template>
  <f7-page>
    <navbar :text="$t('PlanningComponent_edit_dailyPlanning')" />
    <DxPlanningComponent
      :planning="actualDailyPlanning"
    />
    <f7-block-footer
      class="content-action-botton"
    >
      <f7-button
        class="dx-btn-cancel margin-right"
        raised
        type="default"
        text-color="grey"
        styling-mode="outlined"
        @click="goToDailyPlanning"
      >
        {{ $t("CreatePlanning_ButtonCancel_caption") }}
      </f7-button>
      <f7-button
        class="dx-btn-success"
        raised
        type="success"
        styling-mode="contained"
        name="CreatePlanning_ButtonSave_caption"
        @click="updatePlanning"
      >
        {{ $t('CreatePlanning_ButtonSave_caption') }}
      </f7-button>
    </f7-block-footer>
  </f7-page>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import DxPlanningComponent from '../components/Planning/DxPlanningComponent.vue';
import navbar from '../components/NavBar.vue';
import Api from '../services/Api';

export default {
  name: 'EditPlanning',
  components: {
    DxPlanningComponent,
    navbar,
  },
  computed: {
    ...mapState('PlanningManager', ['actualDailyPlanning', 'activitySelected', 'taskSelected', 'dateSelected', 'actualLocationsSelected', 'performanceUnit',
      'performanceCount', 'groupName', 'shiftValue', 'workers', 'items', 'attachments', 'tools', 'groupedSelected', 'workingArea', 'distributed', 'variety', 'showProducts', 'locationsCompletedId']),
    ...mapState('Prescription', ['prescription']),
    ...mapState('greenhouse', [
      'binsContentPlanned',
      'binsContentsForPlanning',
    ]),
    ...mapGetters('greenhouse', [
      'filteredContentsForPlanning',
      'getCategoryCodeByName',
      'getVarietyCodeByName',
      'getClassificationCodeByName',
    ]),
  },
  beforeDestroy() {
    this.setTotalAreaSelected(0);
    this.setNewPrescription();
    this.resetStates();
  },
  methods: {
    goToDailyPlanning() {
      this.$f7.views.main.router.navigate('/dailyPlanning/', { reloadCurrent: true });
    },
    async updatePlanning() {
      const contentArray = this.binsContentPlanned.map((element) => element.content);
      const contentSet = new Set(contentArray);
      if (this.dateSelected.length === 0) {
        this.$f7.dialog.alert(this.$t('CreatePlanning_ValidationDate'));
      } else if (Array.isArray(this.activitySelected)) {
        this.$f7.dialog.alert(this.$t('CreatePlanning_ValidationActivityName'));
      } else if (Array.isArray(this.taskSelected)) {
        this.$f7.dialog.alert(this.$t('CreatePlanning_ValidationTaskName'));
      } else if (this.actualLocationsSelected.length === 0) {
        this.$f7.dialog.alert(this.$t('CreatePlanning_ValidationActualLocationsSelected'));
      } else if (this.workersNotEmpty() === false && this.hasItems() > 0) {
        this.$f7.dialog.alert(this.$t('CreatePlanning_ValidationNumberOfDriver'));
      } else if (this.workersNotEmpty() === false) {
        this.$f7.dialog.alert(this.$t('CreatePlanning_ValidationNumberOfWorker'));
      } else if (this.showProducts && (typeof this.prescription.id === 'undefined' || this.prescription.id === null || this.prescription.id === '')) {
        this.$f7.dialog.alert(this.$t('CreatePlanning_ValidationPrescription'));
      } else if (contentArray.length > contentSet.size) {
        this.$f7.dialog.alert(this.$t('CreatePlanning_Duplicated_Nursery_Rows'));
      } else {
        this.$f7.preloader.show();
        try {
          const nurseryContent = this.binsContentPlanned.map((element) => {
            const filteredContentAvailable = this.filteredContentsForPlanning(
              element.bin,
              element.variety,
              element.category,
              element.classification,
            );
            if (filteredContentAvailable.length > 0) {
              const binContentForPlanning = this.binsContentsForPlanning.filter((content) => content.bin.bin_id === element.bin);
              return {
                bin: binContentForPlanning[0].bin,
                category: this.getCategoryCodeByName(element.category),
                class_type: this.getClassificationCodeByName(element.classification),
                code: element.content,
                count: filteredContentAvailable[0].plants_count,
                variety: this.getVarietyCodeByName(element.variety),
              };
            }
            return [];
          });
          const newPlanning = {
            task_code: this.taskSelected.code,
            activity_code: this.activitySelected.code,
            date: this.dateSelected,
            performanceUnit: this.performanceUnit,
            performanceCount: this.performanceCount,
            shift: this.shiftValue,
            group: this.groupName,
            grouped: this.groupedSelected,
            workers: this.workers.values,
            items: this.items.values,
            attachments: this.attachments.values,
            tools: this.tools.values,
            locations: this.actualLocationsSelected,
            products: this.prescription.products,
            prescription: this.prescription.id,
            area_effective: this.workingArea,
            distributed: this.distributed,
            goal_performance: {
              unit: this.performanceUnit,
              count: this.performanceCount,
              working_area: this.workingArea,
            },
            variety: this.variety,
            nursery: nurseryContent,
            locations_tree: this.locationsCompletedId,
          };
          // this.checkProducts(newPlanning.products);
          await Api.updatePlanning(this.actualDailyPlanning.ID, newPlanning);
          this.goToDailyPlanning(); // TODO: Habría que mostrar alguna notificación de que se editó correctamente
        } catch (reason) {
          const message = this.$helpers.getFilteredErrorMessage(reason);
          if (message.includes('default_') && !message.includes('404')) {
            this.$f7.dialog.alert(message.split('_').pop());
          } else if (!message.includes('404')) {
            this.$f7.dialog.alert(this.$t(message));
          }
        } finally {
          this.$f7.preloader.hide();
        }
      }
    },
    workersNotEmpty() {
      for (let i = 0; i < this.workers.values.length; i += 1) {
        if (this.workers.values[i].count !== 0) {
          return true;
        }
      }
      return false;
    },
    hasItems() {
      let aux = 0;
      for (let i = 0; i < this.items.values.length; i += 1) {
        aux += 1;
      }
      return aux;
    },
    checkProducts(products) {
      for (const product of products) {
        if (typeof product.dosisUt === 'undefined' || product.dosisUt.toLowerCase().includes('kg/') || product.dosisUt.toLowerCase().includes('l/')) {
          product.solution = 0;
          product.solutionUt = '';
        }
      }
    },
    ...mapActions('Prescription', ['setNewPrescription']),
    ...mapActions('TaskManager', ['setTotalAreaSelected']),
    ...mapActions('greenhouse', ['resetStates']),
  },
};
</script>
