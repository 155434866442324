<template>
  <DxDataGrid
    id="gridProductPerformance"
    ref="gridProductPerformance"
    :visible="toolPopup.length !== 0"
    :data-source="toolPopup"
    :show-borders="true"
    :show-column-lines="true"
    :show-row-lines="false"
    :column-auto-width="false"
    :on-row-updated="showButtonSave"
    @exporting="onExportingtoolPopup"
  >
    <DxEditing
      :allow-updating="true"
      :select-text-on-edit-start="selectTextOnEditStart"
      :start-edit-action="startEditAction"
      mode="batch"
    />
    <DxExport
      :enabled="true"
    />
    <DxPaging :page-size="18" />
    <DxSearchPanel
      :visible="true"
      :width="200"
      :highlight-case-sensitive="true"
      :placeholder="$t('Search_placeholder')"
    />
    <DxColumn
      data-field="data.description"
      :caption="$t('DxPopupWorkOrder_description_tool')"
    />
    <DxColumn
      data-field="fuel_consumption"
      data-type="number"
      :editor-options="numberColumnOptions"
      :caption="$t('DxWorkOrders_fuel_consumption_caption')"
    />
    <DxColumn
      data-field="fuel_type"
      :caption="$t('DxToolsMonthlyTable_consumption_type_caption')"
    >
      <DxLookup
        :data-source="typeCosumptionAvailable"
        :display-expr="dysplayConsumptionType"
      />
    </DxColumn>
  </DxDataGrid>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxEditing,
  DxLookup,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import Api from '../../services/Api';

export default {
  name: 'DxProductPerformance',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxEditing,
    DxLookup,
  },
  props: {
    toolPopup: { type: Array, default: () => [] },
  },
  data() {
    return {
      typeCosumptionAvailable: ['DIESEL', 'GASOLINE'],
      productsAvaibles: [],
      selectTextOnEditStart: true,
      startEditAction: 'click',
      numberColumnOptions: {
        showSpinButtons: true, min: 0, showClearButton: true,
      },
    };
  },
  computed: {
    ...mapState('PlanningManager', ['actualWorkOrder']),
  },
  methods: {
    // UTILITIES
    showButtonSave() {
      this.setSaveButtonPopupWorkOrder(false);
    },
    // EXPORT EXCEL
    onExportingtoolPopup(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('ToolPerformance');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ToolPerformance.xlsx');
          });
      });
      e.cancel = true;
    },
    calculateQtyCollected(rowdata) {
      if (rowdata.qty_collected < rowdata.qty_used) {
        return rowdata.qty_used;
      }
      return rowdata.qty_collected;
    },
    calculateQtyUsed(rowdata) {
      if (rowdata.qty_used > rowdata.qty_collected) {
        return rowdata.qty_collected;
      }
      return rowdata.qty_used;
    },
    dysplayConsumptionType(e) {
      if (e === 'DIESEL') {
        return this.$t('DIESEL');
      }
      return this.$t('GASOLINE');
    },
    ...mapActions('PlanningManager', ['setSaveButtonPopupWorkOrder']),
  },

};
</script>
