<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-worker main-dx-nursery-stock"
  >
    <DxDataGrid
      id="stock-grid"
      :data-source="stocksFormated"
      :remote-operations="true"
      :column-min-width="90"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
    >
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxNurseryStockReport"
      />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumnFixing :enabled="true" />
      <DxColumn
        data-field="date"
        :caption="$t('DxTable_Date_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="ut"
        :caption="$t('DxTable_ut_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="type"
        :caption="$t('DxTable_type_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="variety"
        :caption="$t('DxTable_variety_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="plantYear"
        :caption="$t('DxTable_plantYear_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="category"
        :caption="$t('DxTable_category_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="amount"
        :caption="$t('DxTable_amount_caption')"
        data-type="number"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="notes"
        :caption="$t('DxTable_notes_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="typeReclassified"
        :caption="$t('DxNurseryStockReport_typeReclassified_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="pottedYear"
        :caption="$t('DxNurseryStockReport_pottedYear_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="categoryReclassified"
        :caption="$t('DxNurseryStockReport_categoryReclassified_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="finalDestination"
        :caption="$t('DxNurseryStockReport_finalDestination_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxScrolling column-rendering-mode="virtual" />
    </DxDataGrid>
  </div>
</template>

<script>

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxScrolling,
  DxColumnFixing,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
} from 'devextreme-vue/data-grid';
import { mapGetters } from 'vuex';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import moment from 'moment';

export default {
  name: 'DxNurseryStockReport',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
  },
  props: {
    stocks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loaded: false,
      stocksFormated: [],
      keyName: 'DxNurseryStockReport',
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    ...mapGetters('greenhouse', ['getLocationNameLevelTwoByChildId']),
  },
  async beforeMount() {
    try {
      for (const stock of this.stocks) {
        const stockFormated = {
          date: stock.date,
          ut: this.getLocationNameLevelTwoByChildId(stock.origin),
          type: stock.root_category,
          variety: stock.variety,
          plantYear: stock.plant_year,
          category: stock.class_type,
          amount: stock.amount,
          notes: stock.notes,
          typeReclassified: stock.bin_category,
          pottedYear: stock.bin_plant_year,
          categoryReclassified: stock.bin_class_type,
          finalDestination: this.getLocationNameLevelTwoByChildId(stock.destination),
        };
        this.stocksFormated.push(stockFormated);
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.loaded = true;
    }
  },
  methods: {
    getTotal(init, end) {
      return end - init;
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('stock');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `reportingNurseryStock${moment().format('YYYYMMDD_HHmmss')}.xlsx`);
          });
      });
      e.cancel = true;
    },
  },
};
</script>
