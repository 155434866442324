<template>
  <f7-page>
    <navbar :text="$t('ClimateGrids_navbar_title')" />
    <f7-subnavbar class="subnavbar">
      <div class="subnavbar__element">
        <span>Data Frequency:</span>
        <DxSelectBox
          ref="measuringSelect"
          :data-source="measuringFrequency"
          :value="selectedMeasuringFrequency"
          :on-value-changed="updateMeasuringFrequency"
        />
      </div>
      <div class="subnavbar__element">
        <span>Location:</span>
        <DxSelectBox
          ref="locationSelect"
          :data-source="locationsToSelect"
          :value="locationsToSelect[0].bd_id"
          :on-value-changed="updateSelectedFarmID"
          display-expr="name"
          value-expr="bd_id"
        />
      </div>
    </f7-subnavbar>
    <f7-col
      v-if="loaded"
      width="100"
    >
      <div class="content-calendar-block">
        <CalendarInitEndDate
          :key-name="keyName"
          :max-range="30"
        />
      </div>
      <f7-block-title>
        {{ $t('ClimateGrids_DxWeatherHumidityGrid_blockTitle') }}
      </f7-block-title>
      <DxWeatherHumidityGrid
        :data-source="humiditiesDataSource"
        weather-element="Humidity"
      />
      <f7-block-title>
        {{ $t('ClimateGrids_DxWeatherPrecipitationGrid_blockTitle') }}
      </f7-block-title>
      <DxWeatherPrecipitationGrid
        :data-source="precipitationsDataSource"
        weather-element="Precipitation"
      />
      <f7-block-title>
        {{ $t('ClimateGrids_DxWeatherTemperatureGrid_blockTitle') }}
      </f7-block-title>
      <DxWeatherTemperatureGrid
        :data-source="temperaturesDataSource"
        weather-element="Temperature"
      />
    </f7-col>
  </f7-page>
</template>

<script>
import DxSelectBox from 'devextreme-vue/select-box';
import * as overlay from 'devextreme/ui/overlay';
import { mapState, mapGetters, mapActions } from 'vuex';
import DxWeatherHumidityGrid from '../../components/weather/DxWeatherHumidityGrid.vue';
import DxWeatherPrecipitationGrid from '../../components/weather/DxWeatherPrecipitationGrid.vue';
import DxWeatherTemperatureGrid from '../../components/weather/DxWeatherTemperatureGrid.vue';
import navbar from '../../components/NavBar.vue';
import CalendarInitEndDate from '../../components/CalendarInitEndDate.vue';

export default {
  name: 'ClimateGrids',
  components: {
    DxWeatherHumidityGrid,
    DxWeatherPrecipitationGrid,
    DxWeatherTemperatureGrid,
    CalendarInitEndDate,
    navbar,
    DxSelectBox,
  },
  data() {
    return {
      loaded: false,
      locationsAvailables: [],
      locationsToSelect: [],
      temperaturesDataSource: [],
      humiditiesDataSource: [],
      precipitationsDataSource: [],
      indexOfRange: 0,
      keyName: 'climateGrids',
    };
  },
  computed: {
    ...mapState('weather', [
      'measuringFrequency',
      'selectedFarmID',
      'selectedMeasuringFrequency',
    ]),
    ...mapState('Reporting', ['locationsTree', 'selectedFarmList']),
    ...mapGetters('weather', [
      'hourlyMeasuredTemperatures',
      'hourlyForecastedTemperatures',
      'dailyMeasuredTemperatures',
      'dailyForecastedTemperatures',
      'hourlyMeasuredHumidities',
      'hourlyForecastedHumidities',
      'dailyMeasuredHumidities',
      'dailyForecastedHumidities',
      'hourlyMeasuredPrecipitations',
      'hourlyForecastedPrecipitations',
      'dailyMeasuredPrecipitations',
      'dailyForecastedPrecipitations',
    ]),
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  created() {
    this.$f7.preloader.show();
    this.getLocationsAvailables();
    this.locationsAvailables.forEach((location) => {
      if (this.selectedFarmList.includes(location.bd_id)) {
        this.locationsToSelect.push(location);
      }
    });
    if (this.selectedMeasuringFrequency !== this.measuringFrequency[0]) {
      for (let i = 0; i < this.measuringFrequency.length; i += 1) {
        if (this.selectedMeasuringFrequency !== this.measuringFrequency[i]) {
          this.setSelectedMeasuringFrequency(this.measuringFrequency[i]);
        }
      }
    } else {
      this.setSelectedMeasuringFrequency(this.measuringFrequency[0]);
    }
    this.setSelectedFarmID(this.locationsToSelect[0].bd_id).then(() => {
      this.fetchData().then(() => {
        this.updateTemperaturesDataSource();
        this.updateHumiditiesDataSource();
        this.updatePrecipitationsDataSource();
      }).finally(() => {
        this.loaded = true;
        this.$f7.preloader.hide();
      });
    });
  },
  beforeMount() {
    overlay.baseZIndex(999999);
  },
  methods: {
    ...mapActions('weather', [
      'setSelectedFarmID',
      'setSelectedMeasuringFrequency',
      'fetchWeatherForecastData',
      'fetchMeasuredWeatherData',
    ]),
    async fetchData() {
      await this.fetchWeatherForecastData(this.selectedFarmList);
      await this.fetchMeasuredWeatherData(this.selectedFarmList);
    },
    async updateMeasuringFrequency(e) {
      const newMeasuringFrequency = e.value;
      // if (newMeasuringFrequency === 'DAILY') {
      //   this.indexOfRange = 1;
      // }
      this.setSelectedMeasuringFrequency(newMeasuringFrequency);
      await this.updateTemperaturesDataSource();
      await this.updateHumiditiesDataSource();
      await this.updatePrecipitationsDataSource();
    },
    async updateSelectedFarmID(e) {
      const newSelectedFarmID = e.value;
      this.setSelectedFarmID(newSelectedFarmID);
      this.$f7.preloader.show();
      await this.updateTemperaturesDataSource();
      await this.updateHumiditiesDataSource();
      await this.updatePrecipitationsDataSource();
      this.$f7.preloader.hide();
    },
    async updateTemperaturesDataSource() {
      let temperaturesMeasured = [];
      let temperaturesForecast = [];
      const finalData = [];
      switch (this.selectedMeasuringFrequency) {
        case 'RAW': {
          temperaturesMeasured = await this.hourlyMeasuredTemperatures;
          temperaturesForecast = this.hourlyForecastedTemperatures;
          break;
        }
        case 'HOURLY': {
          temperaturesMeasured = await this.hourlyMeasuredTemperatures;
          temperaturesForecast = this.hourlyForecastedTemperatures;
          break;
        }

        case 'DAILY': {
          temperaturesMeasured = await this.dailyMeasuredTemperatures;
          temperaturesForecast = this.dailyForecastedTemperatures;
          break;
        }

        default:
          break;
      }
      // Añadimos en el array final los datos medidos (si falta alguno añadimos los predictivos)
      temperaturesMeasured.forEach((element) => {
        const newEl = {
          maxTemp: parseFloat(element.maxTemp).toFixed(2),
          measuredTemperature: parseFloat(element.measuredTemperature).toFixed(2),
          date: element.date,
          minTemp: parseFloat(element.minTemp).toFixed(2),
        };
        const filteredForecast = temperaturesForecast.filter((e) => e.date === element.date);
        if (filteredForecast.length > 0) {
          if (newEl.maxTemp === undefined || newEl.maxTemp === null) {
            newEl.maxTemp = parseFloat(filteredForecast[0].forecastedTemperature).toFixed(2);
          }
          if (newEl.measuredTemperature === undefined || newEl.measuredTemperature === null) {
            newEl.measuredTemperature = parseFloat(filteredForecast[0].forecastedTemperature).toFixed(2);
          }
          if (newEl.minTemp === undefined || newEl.minTemp === null) {
            newEl.minTemp = parseFloat(filteredForecast[0].forecastedTemperature).toFixed(2);
          }
          temperaturesForecast.splice(temperaturesForecast.indexOf(filteredForecast[0]), 1);
        }
        finalData.push(newEl);
      });
      // Añadimos ahora los datos predictivos sobrantes (si los hay)
      temperaturesForecast.forEach((element) => {
        const newEl = {
          maxTemp: parseFloat(element.forecastedTemperature).toFixed(2),
          measuredTemperature: parseFloat(element.forecastedTemperature).toFixed(2),
          date: element.date,
          minTemp: parseFloat(element.forecastedTemperature).toFixed(2),
        };
        finalData.push(newEl);
      });
      this.temperaturesDataSource = finalData;
    },
    async updateHumiditiesDataSource() {
      let humiditiesMeasured = [];
      let humiditiesForecast = [];
      const finalData = [];
      switch (this.selectedMeasuringFrequency) {
        case 'RAW': {
          humiditiesMeasured = await this.hourlyMeasuredHumidities;
          humiditiesForecast = this.hourlyForecastedHumidities;
          break;
        }
        case 'HOURLY': {
          humiditiesMeasured = await this.hourlyMeasuredHumidities;
          humiditiesForecast = this.hourlyForecastedHumidities;
          break;
        }

        case 'DAILY': {
          humiditiesMeasured = await this.dailyMeasuredHumidities;
          humiditiesForecast = this.dailyForecastedHumidities;
          break;
        }

        default:
          break;
      }
      // Añadimos en el array final los datos medidos (si falta alguno añadimos los predictivos)
      humiditiesMeasured.forEach((element) => {
        const newEl = {
          maxTemp: parseFloat(element.maxTemp).toFixed(2),
          measuredHumidity: parseFloat(element.measuredHumidity).toFixed(2),
          date: element.date,
          minTemp: parseFloat(element.minTemp).toFixed(2),
        };
        const filteredForecast = humiditiesForecast.filter((e) => e.date === element.date);
        if (filteredForecast.length > 0) {
          if (newEl.maxTemp === undefined || newEl.maxTemp === null) {
            newEl.maxTemp = parseFloat(filteredForecast[0].forecastedHumidity).toFixed(2);
          }
          if (newEl.measuredHumidity === undefined || newEl.measuredHumidity === null) {
            newEl.measuredHumidity = parseFloat(filteredForecast[0].forecastedHumidity).toFixed(2);
          }
          if (newEl.minTemp === undefined || newEl.minTemp === null) {
            newEl.minTemp = parseFloat(filteredForecast[0].forecastedHumidity).toFixed(2);
          }
          humiditiesForecast.splice(humiditiesForecast.indexOf(filteredForecast[0]), 1);
        }
        finalData.push(newEl);
      });
      // Añadimos ahora los datos predictivos sobrantes (si los hay)
      humiditiesForecast.forEach((element) => {
        const newEl = {
          maxTemp: parseFloat(element.forecastedHumidity).toFixed(2),
          measuredHumidity: parseFloat(element.forecastedHumidity).toFixed(2),
          date: element.date,
          minTemp: parseFloat(element.forecastedHumidity).toFixed(2),
        };
        finalData.push(newEl);
      });
      this.humiditiesDataSource = finalData;
    },
    async updatePrecipitationsDataSource() {
      let precipitationsMeasured = [];
      let precipitationsForecast = [];
      const finalData = [];
      switch (this.selectedMeasuringFrequency) {
        case 'RAW': {
          precipitationsMeasured = await this.hourlyMeasuredPrecipitations;
          precipitationsForecast = this.hourlyForecastedPrecipitations;
          break;
        }
        case 'HOURLY': {
          precipitationsMeasured = await this.hourlyMeasuredPrecipitations;
          precipitationsForecast = this.hourlyForecastedPrecipitations;
          break;
        }

        case 'DAILY': {
          precipitationsMeasured = await this.dailyMeasuredPrecipitations;
          precipitationsForecast = this.dailyForecastedPrecipitations;
          break;
        }
        default:
          break;
      }
      // Añadimos en el array final los datos medidos (si falta alguno añadimos los predictivos)
      precipitationsMeasured.forEach((element) => {
        const newEl = {
          measuredPrecipitation: parseFloat(element.measuredPrecipitation).toFixed(2),
          date: element.date,
        };
        const filteredForecast = precipitationsForecast.filter((e) => e.date === element.date);
        if (filteredForecast.length > 0) {
          if (newEl.measuredPrecipitation === undefined || newEl.measuredPrecipitation === null) {
            newEl.measuredPrecipitation = parseFloat(filteredForecast[0].forecastedPrecipitation).toFixed(2);
          }
          precipitationsForecast.splice(precipitationsForecast.indexOf(filteredForecast[0]), 1);
        }
        finalData.push(newEl);
      });
      // Añadimos ahora los datos predictivos sobrantes (si los hay)
      precipitationsForecast.forEach((element) => {
        const newEl = {
          measuredPrecipitation: parseFloat(element.forecastedPrecipitation).toFixed(2),
          date: element.date,
        };
        finalData.push(newEl);
      });
      this.precipitationsDataSource = finalData;
    },
    getLocationsAvailables() {
      for (const item of this.locationsTree) {
        this.setLocationTreeFormat(item);
        this.recursiveAddChildrens(item.children);
      }
    },
    recursiveAddChildrens(childrens) {
      for (let i = 0; i < childrens.length; i += 1) {
        this.setLocationTreeFormat(childrens[i]);
        this.recursiveAddChildrens(childrens[i].children);
      }
    },
    setLocationTreeFormat(item) {
      this.locationsAvailables.push({
        name: item.name,
        bd_id: item.bd_id,
        area: item.area,
        ID: item.ID,
        HEAD_ID: item.HEAD_ID,
        children: item.children,
      });
    },
  },
};
</script>
<style lang="scss">
.subnavbar {
    display: flex;
    justify-content: flex-start;
    background: var(--f7-navbar-bg-color);
    border-bottom: 1px solid #e4e6ed;
    &__element {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.subnavbar__element span {
  font-size: 12px;
  padding: 10px;
  text-align: right;
}
.subnavbar-inner {
    justify-content: space-evenly;
}
.content__graphs {
  padding: 0 0 50px;
  background: #e4e6ed;
  min-height: 100vh;
}
.content-calendar-block {
padding-top: 15px;
padding-right: 15px;
}
</style>
