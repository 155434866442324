<template>
  <f7-page>
    <navbar :text="$t('planned_title')" />
    <DxPlannedVsReal
      v-if="loaded"
      :locations-tree="locationsTree"
      :filtered-badgets-tasks="filteredBadgetsTasks"
      :list-of-costs="listOfCosts"
      :daily-real-performance-tasks="dailyRealPerformanceTasks"
      :init-year-badget="checkedInitEndDate.init"
      :end-year-badget="checkedInitEndDate.end"
    />
  </f7-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import * as overlay from 'devextreme/ui/overlay';
import DxPlannedVsReal from '../../components/reporting/DxPlannedVsReal.vue';
import navbar from '../../components/NavBar.vue';
import Api from '../../services/Api';

export default {
  components: {
    DxPlannedVsReal,
    navbar,
  },
  data() {
    return {
      tasksCode: [],
      loaded: false,
      locationsTree: [],
      locationsTreeAux: [],
      locationsFromBudget: [],
      badgets: [],
      listOfCosts: [],
      dailyRealPerformanceTasks: [],
      currentYear: moment().format('yyyy'),
      yearBefore: moment().subtract(1, 'years').format('YYYY'),
      keyName: 'plannedVsReal',
      checkedInitEndDate: {},
      filteredBadgetsTasks: [],
      locationsCompletedId: [],
    };
  },

  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      if (typeof this.actualFilter.plannedVsReal === 'undefined') {
        const currentYear = moment().format('yyyy');
        const yearBefore = moment().subtract(1, 'years').format('YYYY');
        this.setActualFilter({
          keyName: this.keyName,
          init: moment.tz(`${yearBefore}-09-01`, 'UTC').format('YYYY-MM-DD'),
          end: moment.tz(`${currentYear}-08-31`, 'UTC').format('YYYY-MM-DD'),
        });
      }
      this.checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      let xhr = await Api.getMonthlyTasks(this.checkedInitEndDate.init, this.checkedInitEndDate.end);
      this.badgets = JSON.parse(xhr.response).data;
      xhr = await Api.getLocationsTree();
      this.locationsTree = JSON.parse(xhr.response);
      this.getFilteredBadgetsTasks();
      if (this.filteredBadgetsTasks.length !== 0) {
        xhr = await Api.getAllCosts();
        this.listOfCosts = JSON.parse(xhr.response).data;
        xhr = await Api.getRealPerformance(`${this.checkedInitEndDate.init}`,
          `${this.checkedInitEndDate.end}`,
          this.tasksCode,
          this.getFarms(this.locationsFromBudget));
        this.dailyRealPerformanceTasks = JSON.parse(xhr.response).data;
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    getFilteredBadgetsTasks() {
      for (const badget of this.badgets) {
        const lastVersion = badget.version[badget.version.length - 1];
        for (const task of lastVersion.task) {
          this.locationsTreeAux = [...task.locations];
          const taskBadget = {
            badgeId: badget.id,
            versionId: lastVersion.id,
            versionName: lastVersion.name,
            ID: task.id,
            Date: this.formattingDates(task.dates),
            days: task.days,
            Task: task.task_name,
            taskName: task.task,
            taskCode: task.task_code,
            Activity: task.activity,
            activityCode: task.activity_code,
            goal: parseFloat(task.goal),
            goalUnit: task.goal_unit,
            locationsCompletedName: [],
            locations: task.locations,
            temporary: task.temporary,
            permanent: task.permanent,
            contratist: task.contratist,
            cost: parseFloat(task.cost),
            realGoal: 0.00,
            goalVsReal: 0.00,
            realCost: 0.00,
            CostVsReal: 0.00,
          };
          if (moment(taskBadget.Date[0], 'YYYY-MM-DD').isBetween(this.checkedInitEndDate.init, this.checkedInitEndDate.end, undefined, '[]')) {
            if (this.filteredBadgetsTasks.filter((filteredTask) => filteredTask.taskCode === taskBadget.taskCode).length === 0) {
              this.$helpers.addUniqueElementToArray(this.tasksCode, taskBadget.taskCode);
              for (const taskLocation of taskBadget.locations) {
                this.$helpers.addUniqueElementToArray(this.locationsFromBudget, taskLocation);
              }
              this.locationsCompletedId = [];
              this.fetchFullNodeSelected(taskBadget.locations, this.locationsTree);
              this.$helpers.fetchLocationsNames(taskBadget.locationsCompletedName, this.locationsCompletedId, this.locationsTree);
              this.filteredBadgetsTasks.push(taskBadget);
            }
          }
        }
      }
    },
    formattingDates(dates) {
      const formatedDates = [];
      for (const date of dates) {
        formatedDates.push(moment(date).format('YYYY-MM-DD'));
      }
      return formatedDates;
    },
    /**
     * Obtiene las hojas para ese nodo
     */
    getLastChildrens(lastChildrens, location) {
      if (location.children.length === 0) {
        lastChildrens.push(location.bd_id);
      } else {
        for (const children of location.children) {
          this.getLastChildrens(lastChildrens, children);
        }
      }
    },
    /**
     * Obtenemos los nodos que están completamente seleccionados, dando prioridad
     * a los padres, es decir, los que tienen un nivel jerárquico más importante (0 > 1 > 2...)
     */
    fetchFullNodeSelected(selectedLocations, locations) {
      const fullNodes = {};
      for (const location of locations) {
        const lastChildrens = [];
        this.getLastChildrens(lastChildrens, location);
        fullNodes[`${location.bd_id}`] = { lastChildrens, childrens: location.children };
      }
      for (const location in fullNodes) {
        if (Object.hasOwnProperty.call(fullNodes, location)) {
          const { lastChildrens } = fullNodes[location];
          if (this.isFullChildrensSelected(selectedLocations, lastChildrens)) {
            this.$helpers.addUniqueElementToArray(this.locationsCompletedId, location);
          } else {
            this.fetchFullNodeSelected(selectedLocations, fullNodes[location].childrens);
          }
        }
      }
    },
    /**
     * Comprueba si todos sus hijos están seleccionados
     */
    isFullChildrensSelected(selectedLocations, childrens) {
      for (const children of childrens) {
        if (!selectedLocations.includes(children)) {
          return false;
        }
      }
      return true;
    },
    getFarms(locationsFromBudget) {
      const farms = [];
      for (const location of locationsFromBudget) {
        const farm = this.$helpers.getLocationLevel0IdById(location, this.locationsTree);
        this.$helpers.addUniqueElementToArray(farms, farm);
      }
      return farms;
    },
    ...mapActions('CalendarInitEndDate', ['setActualFilter']),
  },
};
</script>

<style>

</style>
