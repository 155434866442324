<template>
  <div class="content__weatherstatus">
    <f7-block v-if="loaded">
      <div class="loadingFalse">
        <div class="preloader" />
      </div>
    </f7-block>
    <f7-block v-else>
      <div class="label">
        <span v-text="$t('WeatherStatus_title')" />
      </div>
      <div>
        <DxTabPanel
          :data-source="weatherStatusFormated"
          :show-nav-buttons="true"
        >
          <template #title="{ data: location }">
            <span>{{ location.location }}</span>
          </template>
          <template #item="{ data: location }">
            <DxDataGrid
              :data-source="location.weekDays"
              :show-row-lines="true"
              :show-column-lines="false"
              :show-column-headers="true"
            >
              <DxColumn
                data-field="weekDay"
                alignment="left"
              />
              <DxColumn
                data-field="temperature"
                alignment="right"
              />
              <DxColumn
                data-field="precipitation"
                alignment="right"
              />
              <DxColumn
                data-field="humidity"
                alignment="right"
              />
            </DxDataGrid>
          </template>
        </DxTabPanel>
      </div>
    </f7-block>
  </div>
</template>

<script>
import { DxTabPanel } from 'devextreme-vue/tab-panel';
import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
import { mapState } from 'vuex';
import moment from 'moment';
import Api from '../../services/Api';
import EventBus from '../../js/event-bus';

export default {
  components: {
    DxTabPanel,
    DxDataGrid,
    DxColumn,
  },
  data() {
    return {
      loaded: true,
      weatherStatus: [],
      weatherStatusFormated: [],
      locationsAvailables: [],
      firstCountDay: 5,
    };
  },
  computed: {
    ...mapState('Reporting', ['selectedFarmList', 'locationsTree']),
  },
  updated() {
    this.loaded = false;
  },
  mounted() {
    EventBus.$on('updateLocations', this.updateLocations);
  },
  beforeDestroy() {
    EventBus.$off('updateLocations', this.updateLocations);
  },
  methods: {
    // ---- OBTENCIÓN Y FORMATEO DE LOCATIONS ----
    async getLocationsAvailables() {
      for (const item of this.locationsTree) {
        this.setLocationTreeFormat(item);
        this.recursiveAddChildrens(item.children);
      }
    },
    recursiveAddChildrens(childrens) {
      for (let i = 0; i < childrens.length; i += 1) {
        this.setLocationTreeFormat(childrens[i]);
        this.recursiveAddChildrens(childrens[i].children);
      }
    },
    setLocationTreeFormat(item) {
      this.locationsAvailables.push({
        name: item.name,
        bd_id: item.bd_id,
        area: item.area,
        ID: item.ID,
        HEAD_ID: item.HEAD_ID,
        children: item.children,
      });
    },
    // ---- OBTENCIóN Y FORMATEO DE WEATHER STATUS ----
    async getWeatherStatus() {
      const weathersStatus = [];
      const datos = await Api.getClimate(this.selectedFarmList, 'MEASURED',
        moment.utc().subtract(this.firstCountDay, 'd').format('YYYY-MM-DDT00:00:00'),
        moment.utc().format('YYYY-MM-DDT23:59:59'), ['TEMPERATURE_AVG', 'PLUVIOMETER', 'HUMIDITY_AVG'], true);
      for (const weatherStatus of JSON.parse(datos.response)) {
        if (this.selectedFarmList.includes(weatherStatus.location)) {
          weathersStatus.push(weatherStatus);
        }
      }
      return weathersStatus;
    },
    formattingWeatherStatus() {
      this.weatherStatusFormated.length = 0;
      for (const item of this.weatherStatus) {
        this.weatherStatusFormated.push({
          locationId: item.location,
          location: this.getLocationNameById(item.location),
          weekDays: this.setDaysAvailables(item),
        });
      }
    },
    getLocationNameById(id) {
      for (const location of this.locationsAvailables) {
        if (location.bd_id === id) {
          return location.name;
        }
      }
      return '';
    },
    setDaysAvailables(item) {
      const weekDays = [];
      for (let i = 1; i <= this.firstCountDay; i += 1) {
        const dayIterate = moment.utc().subtract(i, 'd').format('YYYY-MM-DD');
        const temperature = this.getValeByParameter(item.parameters.TEMPERATURE_AVG, dayIterate);
        const precipitation = this.getValeByParameter(item.parameters.PLUVIOMETER, dayIterate, false);
        const humidity = this.getValeByParameter(item.parameters.HUMIDITY_AVG, dayIterate);
        weekDays.push({
          date: dayIterate,
          weekDay: `${moment(dayIterate).format('DD')} ${moment(
            dayIterate,
          ).format('dddd')}`,
          temperature: `${parseFloat(temperature)} ºC`,
          precipitation: `${parseFloat(precipitation)} mm`,
          humidity: `${parseFloat(humidity)} %`,
        });
      }
      return weekDays;
    },
    getValeByParameter(item, date, calculateAvg = true) {
      const measures = item;
      let sensors = [];
      let amount = 0;
      for (const key in measures) {
        if (Object.hasOwnProperty.call(measures, key)) {
          const measure = measures[key];
          sensors = sensors.concat(this.getAverageByDate(measure, date));
        }
      }
      if (sensors.length === 0) {
        return '---';
      }
      for (const sensor of sensors) {
        amount += sensor;
      }
      if (calculateAvg) {
        amount /= sensors.length;
      }
      amount = amount.toFixed(2);
      return amount;
    },
    getAverageByDate(measure, date) {
      const average = [];
      for (const item of measure) {
        if (date === moment(item.date).format('YYYY-MM-DD')) {
          average.push(parseFloat(item.value));
        }
      }
      return average;
    },
    async updateLocations() {
      this.$f7.preloader.show();
      this.loaded = true;
      try {
        await this.getLocationsAvailables();
        this.weatherStatus = await this.getWeatherStatus();
        this.formattingWeatherStatus();
        this.loaded = false;
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },
  },
};
</script>
<style lang="scss">
.content__weatherstatus
.dx-tabpanel .dx-tab {
  width: auto!important;
  padding: 0;
  font-size: 12px;
  letter-spacing: -0.02em;
  span {
    padding: 4px 8px;
  }
}
</style>
