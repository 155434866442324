// - General
import logo from '../static/img/ha_logo.png';

export const ENV = process.env.NODE_ENV || 'development';
export const { TARGET } = process.env;
export const APP_VERSION = '4.3.2';
export const APPLICATION_NAME = 'FERRERO';

// export const DATABASE_NAME = 'agrotareo';

// export const CONFIG_BASE_URL = ENV === 'development' && TARGET === 'web'
//   ? LOCAL_DEV_PROXY : CONFIG_PRODUCTION_BASE;

// - Backend API
export const SECONDS_TO_REFRESH_AUTOMATICALLY = 60;
export const SECONDS_BETWEEN_SCANS = 3;

// - Color Theme
export const DEFAULT_PRIMARY_COLOR = '#42526E';
export const DEFAULT_SECONDARY_COLOR = '#009BA6';

// Routes
export const APP_LOGO = logo;
