<template>
  <f7-page>
    <navbar :text="this.$t('EditOrder_navbar_title_edit_preworkorder')" />
    <DxWorkOrderEdit
      :actual-work-order="actualWorkOrder"
    />
    <f7-block-footer
      class="content-action-botton"
    >
      <f7-button
        class="dx-btn-cancel margin-right"
        raised
        type="default"
        text-color="grey"
        styling-mode="outlined"
        name="ButtonCancel"
        @click="goToDailyWorkOrders"
      >
        {{ $t('EditOrder_ButtonCancel_caption') }}
      </f7-button>
      <f7-button
        class="dx-btn-success"
        raised
        type="success"
        styling-mode="contained"
        name="ButtonSave"
        @click="updateWorkOrder"
      >
        {{ $t("EditOrder_ButtonSave_caption") }}
      </f7-button>
    </f7-block-footer>
  </f7-page>
</template>

<script>

import { mapState, mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import DxWorkOrderEdit from '../components/workOrderDetail/DxWorkOrderEdit.vue';
import navbar from '../components/NavBar.vue';
import Api from '../services/Api';

export default {
  name: 'EditOrder',
  components: {
    DxWorkOrderEdit,
    navbar,
  },
  props: {
    pathBack: { type: String, default: 'workOrders' },
  },
  computed: {
    ...mapState('PlanningManager', ['actualWorkOrder', 'actualLocationsSelected', 'tools', 'attachments', 'locationsCompletedId']),
    ...mapState('Prescription', ['prescription']),
    ...mapState('greenhouse', [
      'binsContentPlanned',
      'binsContentsForPlanning',
    ]),
    ...mapGetters('greenhouse', [
      'filteredContentsForPlanning',
      'getCategoryCodeByName',
      'getVarietyCodeByName',
      'getClassificationCodeByName',
    ]),
  },
  destroyed() {
    this.setNewPrescription();
    this.resetStates();
  },
  methods: {
    goToDailyWorkOrders() {
      this.$f7.views.main.router.navigate(`/${this.pathBack}/`, { reloadCurrent: true });
    },
    async updateWorkOrder() {
      if (typeof this.actualWorkOrder.itemsWorkOrder === 'undefined') {
        this.actualWorkOrder.itemsWorkOrder = [];
      }
      const contentArray = this.binsContentPlanned.map((element) => element.content);
      const contentSet = new Set(contentArray);
      if (this.actualLocationsSelected.length === 0) {
        this.$f7.dialog.alert(this.$t('CreatePlanning_ValidationActualLocationsSelected'));
      } else if (this.actualWorkOrder.supervisorsLocation.length === 0) {
        this.$f7.dialog.alert(this.$t('CreateOrder_ValidationSupervisorsLocation'));
      } else if (contentArray.length > contentSet.size) {
        this.$f7.dialog.alert(this.$t('CreatePlanning_Duplicated_Nursery_Rows'));
      } else {
        this.$f7.preloader.show();
        try {
          const nurseryContent = this.binsContentPlanned.map((element) => {
            const filteredContentAvailable = this.filteredContentsForPlanning(
              element.bin,
              element.variety,
              element.category,
              element.classification,
            );
            if (filteredContentAvailable.length > 0) {
              const binContentForPlanning = this.binsContentsForPlanning.filter((content) => content.bin.bin_id === element.bin);
              return {
                bin: binContentForPlanning[0].bin,
                category: this.getCategoryCodeByName(element.category),
                class_type: this.getClassificationCodeByName(element.classification),
                code: element.content,
                count: filteredContentAvailable[0].plants_count,
                variety: this.getVarietyCodeByName(element.variety),
              };
            }
            return [];
          });
          const items = this.getItems(this.actualWorkOrder.itemsWorkOrder);
          const updateOrder = { // TODO: Falta obtener el delta para solo enviar los cambios
            date: this.actualWorkOrder.Date,
            location: this.actualLocationsSelected,
            responsible: this.actualWorkOrder.supervisorsLocation,
            comment: this.actualWorkOrder.additionalInfoWorkOrder,
            items,
            items_drivers: this.actualWorkOrder.itemsDriversWorkOrder,
            attachments_vehicles: this.attachments.values,
            prescription: this.prescription.id,
            products: this.prescription.products,
            nursery: nurseryContent,
            locations_tree: this.locationsCompletedId,
          };
          await Api.updateOrder(this.actualWorkOrder.ID, updateOrder);
          this.goToDailyWorkOrders(); // TODO: Habría que mostrar alguna notificación de que se editó correctamente
        } catch (reason) {
          const message = this.$helpers.getFilteredErrorMessage(reason);
          if (message.includes('default_') && !message.includes('404')) {
            this.$f7.dialog.alert(message.split('_').pop());
          } else if (!message.includes('404')) {
            this.$f7.dialog.alert(this.$t(message));
          }
        } finally {
          this.$f7.preloader.hide();
        }
      }
    },
    getItems(itemsWorkOrder) {
      const items = [];
      for (const itemWorkOrder of itemsWorkOrder) {
        for (const id of itemWorkOrder.ids) {
          items.push(id);
        }
      }
      return items;
    },
    getCountItemsbySubfamily(itemsWorkOrder, subfamily) {
      for (const itemWorkOrder of itemsWorkOrder) {
        if (itemWorkOrder.subfamily === subfamily) {
          return itemWorkOrder.ids.length;
        }
      }
      return 0;
    },
    checkDrivers(itemsDriversWorkOrder) {
      // Los items no son obligatorios, sólo se deben chequear cuando existan
      if (typeof itemsDriversWorkOrder === 'undefined' || itemsDriversWorkOrder === null) {
        return true;
      }
      for (const item of itemsDriversWorkOrder) {
        if (item.driver === '') {
          return false;
        }
      }
      return true;
    },
    checkAttachments() {
      for (const attachment of this.attachments.values) {
        if (typeof attachment.vehicle === 'undefined' || attachment.vehicle === '') {
          return false;
        }
      }
      return true;
    },
    ...mapActions('Prescription', ['setNewPrescription']),
    ...mapActions('greenhouse', ['resetStates']),
  },
};
</script>
