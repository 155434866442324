export default {
  namespaced: true,
  state: {
    actualDailyPlanning: {},
    actualLocationsSelected: [],
    actualLocationsTreeFormated: [],
    // Datos que guardamos de Create Planning.
    activitySelected: [],
    taskSelected: [],
    dateSelected: [],
    performanceUnit: '',
    performanceCount: 0,
    shiftValue: '',
    groupedSelected: false,
    groupName: '',
    workers: { family: 'WORKER_TYPE', values: [] },
    items: { family: 'ITEM_SUBFAMILY_VEHICLE', values: [] },
    attachments: { family: 'ITEM_SUBFAMILY_ATTACHMENT', values: [] },
    tools: { family: 'ITEM_SUBFAMILY_TOOLS', values: [] },
    actualWorkOrder: {},
    saveButtonPopupWorkOrder: true,
    visiblePopupWorkOrder: false,
    showProducts: false,
    employees: [],
    listProductivityUnit: [
      { id: 'PLANTS', name: 'PLANTS' },
      { id: 'M', name: 'M' },
      { id: 'HA', name: 'HA' },
      { id: 'HOURS', name: 'HOURS' },
      { id: 'M2', name: 'M2' },
      { id: 'MAN/DAY', name: 'MAN/DAY' },
      { id: 'KG', name: 'KG' },
      { id: 'M3', name: 'M3' }],
    fechaInicio: '',
    workingArea: 0,
    distributed: false,
    machinarySelected: [],
    variety: '',
    annualBudget: {},
    monthlyPlanningEditable: true,
    actualLocationsSelectedLevel0: [],
    actualHeadLevelLocationSelected: [],
    actualLocationsSelectedNoRecursive: [],
    kgCollected: 0,
    isWorderStatusCompleted: false,
    back: 'workOrders',
    locationsCompletedId: [],
    keyName: '',
  },

  getters: {
    actualDailyPlanning: (state) => state.actualDailyPlanning,
    actualLocationsSelected: (state) => state.actualLocationsSelected,
    actualLocationsTreeFormated: (state) => state.actualLocationsTreeFormated,
    // Datos que obtenemos de create Planning.
    activitySelected: (state) => state.activitySelected,
    taskSelected: (state) => state.taskSelected,
    dateSelected: (state) => state.dateSelected,
    performanceUnit: (state) => state.performanceUnit,
    performanceCount: (state) => state.performanceCount,
    shiftValue: (state) => state.shiftValue,
    groupedSelected: (state) => state.groupedSelected,
    groupName: (state) => state.groupName,
    workers: (state) => state.workers,
    items: (state) => state.items,
    attachments: (state) => state.attachments,
    tools: (state) => state.tools,
    // Datos que obtenemos de create Order detail.
    actualWorkOrder: (state) => state.actualWorkOrder,
    supervisorsLocation: (state) => state.actualWorkOrder.supervisorsLocation,
    itemsWorkOrder: (state) => state.actualWorkOrder.itemsWorkOrder,
    itemsDriversWorkOrder: (state) => state.actualWorkOrder.itemsDriversWorkOrder,
    dateWorkOrder: (state) => state.actualWorkOrder.Date,
    additionalInfoWorkOrder: (state) => state.actualWorkOrder.additionalInfoWorkOrder,
    machineryPopup: (state) => state.actualWorkOrder.machineryPopup,
    saveButtonPopupWorkOrder: (state) => state.saveButtonPopupWorkOrder,
    visiblePopupWorkOrder: (state) => state.visiblePopupWorkOrder,
    showProducts: (state) => state.showProducts,
    employees: (state) => state.employees,
    listProductivityUnit: (state) => state.listProductivityUnit,
    fechaInicio: (state) => state.fechaInicio,
    workingArea: (state) => state.workingArea,
    distributed: (state) => state.distributed,
    machinarySelected: (state) => state.machinarySelected,
    variety: (state) => state.variety,
    manualPerformance: (state) => state.actualWorkOrder.manualPerformance,
    goalPerformanceUnit: (state) => state.actualWorkOrder.Planner.goal_performance.unit,
    annualBudgetVersion: (state) => state.annualBudget.annualBudgetVersion,
    annualBudgetYear: (state) => state.annualBudget.annualBudgetYear,
    annualBudgetId: (state) => state.annualBudget.annualBudgetId,
    annualBudgetVersionId: (state) => state.annualBudget.annualBudgetVersionId,
    monthlyPlanningEditable: (state) => state.monthlyPlanningEditable,
    actualLocationsSelectedLevel0: (state) => state.actualLocationsSelectedLevel0,
    actualHeadLevelLocationSelected: (state) => state.actualHeadLevelLocationSelected,
    setActualLocationsSelectedNoRecursive: (state) => state.setActualLocationsSelectedNoRecursive,
    kgCollected: (state) => state.kgCollected,
    isWorderStatusCompleted: (state) => state.isWorderStatusCompleted,
    back: (state) => state.back,
  },

  actions: {
    setmachinarySelected(context, value) {
      context.commit('setmachinarySelected', value);
    },
    setActualDailyPlanning(context, value) {
      context.commit('setActualDailyPlanning', value);
    },
    setActualLocationsSelected(context, value) {
      context.commit('setActualLocationsSelected', value);
      context.dispatch('greenhouse/updateSelectedLocationToMove', value, { root: true });
    },
    setActualLocationsTreeFormated(context, value) {
      context.commit('setActualLocationsTreeFormated', value);
    },
    // Datos que seteamos de Create Planning.
    setActivitySelected(context, value) {
      context.commit('setActivitySelected', value);
    },
    setTaskSelected(context, value) {
      context.commit('setTaskSelected', value);
    },
    setDateSelected(context, value) {
      context.commit('setDateSelected', value);
    },
    setPerformanceUnit(context, value) {
      context.commit('setPerformanceUnit', value);
    },
    setPerformanceCount(context, value) {
      context.commit('setPerformanceCount', value);
    },
    setShift(context, value) {
      context.commit('setShift', value);
    },
    setGroupedSelected(context, value) {
      context.commit('setGroupedSelected', value);
    },
    setGroupName(context, value) {
      context.commit('setGroupName', value);
    },
    setWorkers(context, value) {
      context.commit('setWorkers', value);
    },
    setNewItems(context, value) {
      context.commit('setNewItems', value);
    },
    setItems(context, value) {
      context.commit('setItems', value);
    },
    setAttachments(context, value) {
      context.commit('setAttachments', value);
    },
    setTools(context, value) {
      context.commit('setTools', value);
    },
    // Datos que seteamos de create Order detail.
    setSupervisorsLocation(context, value) {
      context.commit('setSupervisorsLocation', value);
    },
    setItemsWorkOrder(context, value) {
      context.commit('setItemsWorkOrder', value);
    },
    setItemsDriversWorkOrder(context, value) {
      context.commit('setItemsDriversWorkOrder', value);
    },
    setDateWorkOrder(context, value) {
      context.commit('setDateWorkOrder', value);
    },
    setAdditionalInfoWorkOrder(context, value) {
      context.commit('setAdditionalInfoWorkOrder', value);
    },
    setActualWorkOrder(context, value) {
      context.commit('setActualWorkOrder', value);
    },
    deleteActualWorkOrder(context) {
      context.commit('deleteActualWorkOrder');
    },
    setMachineryPopup(context, value) {
      context.commit('setMachineryPopup', value);
    },
    setSaveButtonPopupWorkOrder(context, value) {
      context.commit('setSaveButtonPopupWorkOrder', value);
    },
    setVisiblePopupWorkOrder(context, value) {
      context.commit('setVisiblePopupWorkOrder', value);
    },
    setShowProducts(context, value) {
      context.commit('setShowProducts', value);
    },
    setEmployees(context, value) {
      context.commit('setEmployees', value);
    },
    setFechaInicio(context, value) {
      context.commit('setFechaInicio', value);
    },
    setWorkingArea(context, value) {
      context.commit('setWorkingArea', value);
    },
    setDistributed(context, value) {
      context.commit('setDistributed', value);
    },
    initStore({ commit }) {
      commit('setActivitySelected', []);
      commit('setTaskSelected', []);
      commit('setDateSelected', []);
      commit('setPerformanceUnit', '');
      commit('setPerformanceCount', 0);
      commit('setShift', '');
      commit('setGroupedSelected', false);
      commit('setGroupName', '');
      commit('setWorkers', { family: 'WORKER_TYPE', values: [] });
      commit('setNewItems', { family: 'ITEM_SUBFAMILY_VEHICLE', values: [] });
      commit('setAttachments', { family: 'ITEM_SUBFAMILY_ATTACHMENT', values: [] });
      commit('setTools', { family: 'ITEM_SUBFAMILY_TOOLS', values: [] });
      commit('setActualWorkOrder', {});
      commit('setSaveButtonPopupWorkOrder', true);
      commit('setVisiblePopupWorkOrder', false);
      commit('setShowProducts', false);
      commit('setEmployees', []);
      commit('setWorkingArea', 0);
      commit('SET_KG_COLLECTED', 0);
    },
    setResponsible(context, value) {
      context.commit('setResponsible', value);
    },
    setVariety(context, value) {
      context.commit('setVariety', value);
    },
    setManualPerformance(context, value) {
      context.commit('setManualPerformance', value);
    },
    setUnitManualPerformance(context, value) {
      context.commit('setUnitManualPerformance', value);
    },
    setAnnualBudgetVersion(context, value) {
      context.commit('setAnnualBudgetVersion', value);
    },
    setAnnualBudgetYear(context, value) {
      context.commit('setAnnualBudgetYear', value);
    },
    setAnnualBudgetId(context, value) {
      context.commit('setAnnualBudgetId', value);
    },
    setAnnualBudgetVersionId(context, value) {
      context.commit('setAnnualBudgetVersionId', value);
    },
    setMonthlyPlanningEditable(context, value) {
      context.commit('setMonthlyPlanningEditable', value);
    },
    setActualLocationsSelectedLevel0(context, value) {
      context.commit('setActualLocationsSelectedLevel0', value);
    },
    setActualHeadLevelLocationSelected(context, value) {
      context.commit('setActualHeadLevelLocationSelected', value);
    },
    setActualLocationsSelectedNoRecursive(context, value) {
      context.commit('setActualLocationsSelectedNoRecursive', value);
    },
    setKgCollected(context, value) {
      context.commit('SET_KG_COLLECTED', value);
    },
    setIsWorderStatusCompleted(context, value) {
      context.commit('setIsWorderStatusCompleted', value);
    },
    setBack(context, value) {
      context.commit('setBack', value);
    },
    setLocationsCompletedId(context, value) {
      context.commit('setLocationsCompletedId', value);
    },
    setKeyName(context, value) {
      context.commit('setKeyName', value);
    },
  },
  mutations: {
    setmachinarySelected(state, value) {
      state.machinarySelected = value;
    },
    setActualDailyPlanning(state, value) {
      state.actualDailyPlanning = value;
    },
    setActualLocationsSelected(state, value) {
      state.actualLocationsSelected = value;
    },
    setActualLocationsTreeFormated(state, value) {
      state.actualLocationsTreeFormated = value;
    },
    setActivitySelected(state, value) {
      state.activitySelected = value;
    },
    setTaskSelected(state, value) {
      state.taskSelected = value;
    },
    setDateSelected(state, value) {
      state.dateSelected = value;
    },
    setPerformanceUnit(state, value) {
      state.performanceUnit = value;
    },
    setPerformanceCount(state, value) {
      state.performanceCount = value;
    },
    setShift(state, value) {
      state.shiftValue = value;
    },
    setGroupedSelected(state, value) {
      state.groupedSelected = value;
    },
    setGroupName(state, value) {
      state.groupName = value;
    },
    setWorkers(state, value) {
      state.workers.values = value;
    },
    setNewItems(state, value) {
      state.items = value;
    },
    setItems(state, value) {
      state.items.values = value;
    },
    setAttachments(state, value) {
      state.attachments.values = value;
    },
    setTools(state, value) {
      state.tools.values = value;
    },
    setSupervisorsLocation(state, value) {
      state.actualWorkOrder.supervisorsLocation = value;
    },
    setItemsWorkOrder(state, value) {
      state.actualWorkOrder.itemsWorkOrder = value;
    },
    setItemsDriversWorkOrder(state, value) {
      state.actualWorkOrder.itemsDriversWorkOrder = value;
    },
    setDateWorkOrder(state, value) {
      state.actualWorkOrder.Date = value;
    },
    setAdditionalInfoWorkOrder(state, value) {
      state.actualWorkOrder.additionalInfoWorkOrder = value;
    },
    setActualWorkOrder(state, value) {
      state.actualWorkOrder = value;
    },
    deleteActualWorkOrder(state) {
      state.actualWorkOrder = {};
    },
    setMachineryPopup(state, value) {
      state.actualWorkOrder.machineryPopup = value;
    },
    setSaveButtonPopupWorkOrder(state, value) {
      state.saveButtonPopupWorkOrder = value;
    },
    setVisiblePopupWorkOrder(state, value) {
      state.visiblePopupWorkOrder = value;
    },
    setShowProducts(state, value) {
      state.showProducts = value;
    },
    setEmployees(state, value) {
      state.employees = value;
    },
    setFechaInicio(state, value) {
      state.fechaInicio = value;
    },
    setWorkingArea(state, value) {
      state.workingArea = value;
    },
    setDistributed(state, value) {
      state.distributed = value;
    },
    setResponsible(state, value) {
      state.actualWorkOrder.responsible = value;
    },
    setVariety(state, value) {
      state.variety = value;
    },
    setManualPerformance(state, value) {
      state.actualWorkOrder.manualPerformance.total = value;
    },
    setUnitManualPerformance(state, value) {
      state.actualWorkOrder.manualPerformance.unit = value;
    },
    setAnnualBudgetVersion(state, value) {
      state.annualBudget.annualBudgetVersion = value;
    },
    setAnnualBudgetYear(state, value) {
      state.annualBudget.annualBudgetYear = value;
    },
    setAnnualBudgetId(state, value) {
      state.annualBudget.annualBudgetId = value;
    },
    setAnnualBudgetVersionId(state, value) {
      state.annualBudget.annualBudgetVersionId = value;
    },
    setMonthlyPlanningEditable(state, value) {
      state.monthlyPlanningEditable = value;
    },
    setActualLocationsSelectedLevel0(state, value) {
      state.actualLocationsSelectedLevel0 = value;
    },
    setActualHeadLevelLocationSelected(state, value) {
      state.actualHeadLevelLocationSelected = value;
    },
    setActualLocationsSelectedNoRecursive(state, value) {
      state.actualLocationsSelectedNoRecursive = value;
    },
    SET_KG_COLLECTED(state, value) {
      state.kgCollected = value;
    },
    setIsWorderStatusCompleted(state, value) {
      state.isWorderStatusCompleted = value;
    },
    setBack(state, value) {
      state.back = value;
    },
    setLocationsCompletedId(state, value) {
      state.locationsCompletedId = value;
    },
    setKeyName(state, value) {
      state.keyName = value;
    },
  },
};
