<template>
  <f7-page>
    <div v-if="loaded">
      <navbar :text="$t('Confirmar_WorkOrder_Navbar_Title')" />
      <DxPlanningComponent
        class="content-planning"
        :show-tables="false"
        :calendar-multiple="false"
        :max-date="actualDate"
        :distributed-visible="false"
        :actual-work-order="actualWorkOrder"
        :is-task-order-completed="isTaskOrderCompleted"
      />
      <div class="content-devx main-create-post-work">
        <f7-row>
          <f7-col width="100">
            <f7-block-title name="select_supervisor">
              {{ $t("WorkOrderDetail_select_supervisor") }}:
            </f7-block-title>
            <DxPostWorkSelectSupervisor
              :supervisors-availables="supervisors"
              :actual-work-order="actualWorkOrder"
              :is-task-order-completed="isTaskOrderCompleted"
            />
          </f7-col>
          <f7-col width="100">
            <f7-block-title>
              {{ $t('DxEmployeeTable_Title') }}:
            </f7-block-title>
            <DxPostWorkEmployeeTable
              :employees-array="normalEmployees"
              :actual-work-order="actualWorkOrder"
              :is-task-order-completed="isTaskOrderCompleted"
            />
          </f7-col>
          <f7-col width="100">
            <div v-if="showProducts">
              <f7-block-title>
                {{ $t('DxProducts_Title') }}:
              </f7-block-title>
              <DxPostWorkProducts
                :actual-work-order="actualWorkOrder"
                :is-task-order-completed="isTaskOrderCompleted"
                :default-list-prescriptions="listPrescriptions"
                :data-prescription="subFamilyProducts"
              />
            </div>
            <f7-block-title>
              {{ $t('DxMachinery_Title') }}:
            </f7-block-title>
            <DxPostWorkMachinaryDriverTable
              :items-availables="itemsAvailables"
              :drivers-availables="drivers"
              :types="typesMachinary"
              :actual-work-order="actualWorkOrder"
              :is-task-order-completed="isTaskOrderCompleted"
              :updating="true"
              :deleting="true"
              :adding="true"
            />
          </f7-col>
          <f7-col width="100">
            <f7-block-title>
              {{ $t('DxAttachment_Title') }}:
            </f7-block-title>
            <DxPostWorkAttachmentMachinaryTable
              :types="typesAttachmentList"
              :attachment-list="attachmentList"
              :machinary-availables="itemsAvailables"
              :actual-work-order="actualWorkOrder"
              :is-task-order-completed="isTaskOrderCompleted"
              :types-machinery="typesMachinary"
              :updating="true"
              :deleting="true"
              :adding="true"
            />
          </f7-col>
          <f7-col width="100">
            <f7-block-title>
              {{ $t('DxTools_Title') }}:
            </f7-block-title>
            <DxToolsTable
              :tools-types-availables="toolList"
              :tools-availables="typesTool"
              :is-task-order-completed="isTaskOrderCompleted"
              :actual-work-order="actualWorkOrder"
              :allow-updating="true"
              :allow-deleting="true"
              :allow-adding="true"
              :is-post-work="true"
            />
          </f7-col>
          <f7-col
            v-if="showBinsPlanningForm"
            width="100"
          >
            <f7-block-title>
              {{ $t('DxNursery_Title') }}
            </f7-block-title>
            <DxBinsContentGridPlanning />
          </f7-col>
        </f7-row>
      </div>
      <f7-block-footer
        class="content-action-botton"
      >
        <f7-button
          class="dx-btn-cancel margin-right"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          @click="goToPostWork"
        >
          {{ $t("CreatePlanning_ButtonCancel_caption") }}
        </f7-button>
        <f7-button
          class="dx-btn-success margin-right"
          raised
          type="success"
          styling-mode="contained"
          name="CreatePlanning_ButtonSave_caption"
          @click="editWorkOrder"
        >
          {{ $t('CreatePlanning_ButtonSave_caption') }}
        </f7-button>
        <f7-button
          class="dx-btn-confirm"
          raised
          type="success"
          styling-mode="contained"
          name="ConfirmWorkOrder_ButtonSave_caption"
          @click="confirmWorkOrder"
        >
          {{ $t('Edit_and_Confirm') }}
        </f7-button>
      </f7-block-footer>
    </div>
  </f7-page>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import DxPlanningComponent from '../components/Planning/DxPlanningComponent.vue';
import DxPostWorkAttachmentMachinaryTable from '../components/postWork/DxPostWorkAttachmentMachinaryTable.vue';
import DxPostWorkMachinaryDriverTable from '../components/postWork/DxPostWorkMachinaryDriverTable.vue';
import DxToolsTable from '../components/Planning/DxToolsTable.vue';
import DxPostWorkSelectSupervisor from '../components/postWork/DxPostWorkSelectSupervisor.vue';
import DxPostWorkProducts from '../components/postWork/DxPostWorkProducts.vue';
import DxPostWorkEmployeeTable from '../components/postWork/DxPostWorkEmployeeTable.vue';
import DxBinsContentGridPlanning from '../components/greenhouse/DxBinsContentGridPlanning.vue';

import navbar from '../components/NavBar.vue';
import Api from '../services/Api';

export default {
  name: 'EditWorkOrder',
  components: {
    DxPlanningComponent,
    DxPostWorkAttachmentMachinaryTable,
    DxPostWorkMachinaryDriverTable,
    DxPostWorkSelectSupervisor,
    DxToolsTable,
    DxPostWorkProducts,
    DxPostWorkEmployeeTable,
    navbar,
    DxBinsContentGridPlanning,
  },
  props: {
    fullNameFilter: { type: String, default: '' },
    limitFilter: { type: Number, default: 9999 },
    pageFilter: { type: Number, default: 0 },
    pathBack: { type: String, default: 'workOrders' },
  },
  data() {
    return {
      loaded: false,
      itemsAvailables: [],
      attachmentList: [],
      toolList: [],
      typesAttachmentList: [],
      typesMachinary: [],
      actualDate: moment().format('MM/DD/YYYY'),
      typesTool: [],
      isTaskOrderCompleted: false,
      listPrescriptions: [],
      subFamilyProducts: [],
    };
  },
  computed: {
    ...mapState('Employee', ['normalEmployees', 'supervisors', 'drivers']),
    ...mapState('PlanningManager', ['activitySelected', 'taskSelected', 'dateSelected', 'workingArea',
      'actualLocationsSelected', 'performanceUnit', 'performanceCount', 'actualWorkOrder', 'employees',
      'workers', 'items', 'attachments', 'tools', 'groupedSelected', 'shiftValue', 'groupName',
      'supervisorsLocation', 'variety', 'kgCollected', 'isWorderStatusCompleted', 'showProducts',
      'back']),
    ...mapState('TaskManager', ['totalAreaSelected']),
    ...mapState('Prescription', ['prescription']),
    ...mapState('greenhouse', [
      'binsContentPlanned',
      'binsContentsForPlanning',
    ]),
    ...mapGetters('greenhouse', [
      'filteredContentsForPlanning',
      'getCategoryCodeByName',
      'getVarietyCodeByName',
      'getClassificationCodeByName',
      'showBinsPlanningForm',
    ]),
  },
  beforeDestroy() {
    this.setTotalAreaSelected(0);
    this.setNewPrescription();
  },
  async beforeMount() {
    try {
      this.$f7.preloader.show();
      // Cargamos attachment
      let xhr = await Api.getDataSubfamilyByType('machinery');
      const machineryAttachment = JSON.parse(xhr.response);
      this.setDataAttachment(machineryAttachment);
      // Cargamos tools
      xhr = await Api.getDataSubfamilyByType('tool');
      const dataTools = JSON.parse(xhr.response);
      this.setDataTool(dataTools);
      // Cargamos la subfamilia de productos.
      this.subFamilyProducts = await this.getDataSubfamilyByType();
      // Cargamos las prescripciones solo cuando sean necesarias.
      if (this.actualWorkOrder.planner.prescription) {
        this.listPrescriptions = await this.getPrescriptions('', true);
      }
      this.isTaskOrderCompleted = true;
    } catch (error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    async getDataSubfamilyByType() {
      const xhr = await Api.getDataSubfamilyByType('product');
      return JSON.parse(xhr.response);
    },
    async getPrescriptions() {
      const xhr = await Api.getPrescriptions();
      return JSON.parse(xhr.response);
    },
    setDataAttachment(dataMachinaryAttachments) {
      this.itemsAvailables.length = 0;
      this.attachmentList.length = 0;
      this.typesAttachmentList.length = 0;
      this.typesMachinary.length = 0;
      this.typesTool.length = 0;
      for (let i = 0; i < dataMachinaryAttachments.length; i += 1) {
        if (dataMachinaryAttachments[i].id === 'VEHICLE') {
          for (let j = 0; j < dataMachinaryAttachments[i].items.length; j += 1) {
            this.itemsAvailables.push(dataMachinaryAttachments[i].items[j]);
            this.typesMachinary.push({ id: dataMachinaryAttachments[i].items[j].id, name: dataMachinaryAttachments[i].items[j].id });
          }
        } else if (dataMachinaryAttachments[i].id === 'ATTACHMENT') {
          for (let j = 0; j < dataMachinaryAttachments[i].items.length; j += 1) {
            this.attachmentList.push(dataMachinaryAttachments[i].items[j]);
            this.typesAttachmentList.push({ id: dataMachinaryAttachments[i].items[j].id, name: dataMachinaryAttachments[i].items[j].id });
          }
        }
      }
    },
    setDataTool(tools) {
      this.toolList.length = 0;
      for (let i = 0; i < tools.length; i += 1) {
        if (tools[i].id === 'TOOL') {
          for (let j = 0; j < tools[i].items.length; j += 1) {
            if (tools[i].items[j].items.length > 0) {
              this.toolList.push({
                name: tools[i].items[j].id,
                id: tools[i].items[j].items[0].item,
              });
            }
            for (let k = 0; k < tools[i].items[j].items.length; k += 1) {
              this.typesTool.push({
                id: tools[i].items[j].items[k].id,
                description: tools[i].items[j].items[k].description,
                item: tools[i].items[j].items[k].item,
              });
            }
          }
          break;
        }
      }
    },
    goToPostWork() {
      this.$f7.views.main.router.navigate(`/${this.back}/`, { reloadCurrent: true });
    },
    async confirmWorkOrder() {
      await this.updateWorkOrder(true);
    },
    async editWorkOrder() {
      await this.updateWorkOrder();
    },
    async updateWorkOrder(close = false) {
      if (this.dateSelected === '') {
        this.$f7.dialog.alert(this.$t('CreatePlanning_ValidationDate'));
      } else if (Array.isArray(this.activitySelected) && this.actualWorkOrder.activity === '') {
        this.$f7.dialog.alert(this.$t('CreatePlanning_ValidationActivityName'));
      } else if (Array.isArray(this.taskSelected) && this.actualWorkOrder.task === '') {
        this.$f7.dialog.alert(this.$t('CreatePlanning_ValidationTaskName'));
      } else if (this.actualLocationsSelected.length === 0 || this.actualWorkOrder.location.length === 0) {
        this.$f7.dialog.alert(this.$t('CreatePlanning_ValidationActualLocationsSelected'));
      } else if (typeof (this.employees) === 'undefined' || (this.employees.length === 0 || this.actualWorkOrder.workers.length === 0)) {
        this.$f7.dialog.alert(this.$t('CreatePlanning_ValidationNumberOfWorker'));
      } else if (typeof this.actualWorkOrder.supervisorsLocation === 'undefined' && this.actualWorkOrder.responsible === '') {
        this.$f7.dialog.alert(this.$t('DxSelectSupervisor'));
      } else if (!this.checkCheckInWorkers) {
        this.$f7.dialog.alert(this.$t('DxPostWorkEmployeeTable_dialogCreate_wrongHour'));
      } else {
        this.$f7.preloader.show();
        try {
          // Se preparan los empleados para su envío a back
          const newEmployees = [];
          const duplicated = [];
          const previousEmployees = JSON.parse(JSON.stringify(this.employees));
          const idEmployees = JSON.parse(JSON.stringify(typeof this.employees[0].employees_ids !== 'undefined' ? this.employees[0].employees_ids : this.employees[0].id));
          previousEmployees.forEach((element) => {
            element.fullname.forEach((name) => {
              const newWorker = JSON.parse(JSON.stringify(element));
              newWorker.fullname = name;
              newWorker.employee_id = this.employees[0].employees_ids ? this.employees[0].employees_ids[newEmployees.length].id : newWorker.id;
              newWorker.id = newWorker.employee_id;
              if (Array.isArray(idEmployees)) {
                idEmployees.splice(0, 1);
                if (newEmployees.filter((e) => e.employee_id === newWorker.employee_id).length > 0) {
                  duplicated.push(newWorker.fullname);
                }
              }
              newEmployees.push(newWorker);
            });
          });
          if (duplicated.length > 0) {
            this.$f7.dialog.alert(this.$t('CreatePlanning_DuplicatedEmployees') + duplicated);
          } else {
            const vehicles = [];
            for (let i = 0; i < this.items.length; i += 1) {
              vehicles.push(this.items[i].item);
            }
            const nurseryContent = this.binsContentPlanned.map((element) => {
              const filteredContentAvailable = this.filteredContentsForPlanning(
                element.bin,
                element.variety,
                element.category,
                element.classification,
              );
              if (filteredContentAvailable.length > 0) {
                const binContentForPlanning = this.binsContentsForPlanning.filter((content) => content.bin.bin_id === element.bin);
                return {
                  bin: binContentForPlanning[0].bin,
                  category: this.getCategoryCodeByName(element.category),
                  class_type: this.getClassificationCodeByName(element.classification),
                  code: element.content,
                  count: filteredContentAvailable[0].plants_count,
                  variety: this.getVarietyCodeByName(element.variety),
                };
              }
              return [];
            });
            const toolsPlanning = this.inverterTools(this.tools.values);
            const newOrder = {
              date: this.dateSelected[0],
              responsible: this.actualWorkOrder.supervisorsLocation,
              workers: newEmployees,
              machinery_performance: this.items.values,
              product_performance: this.prescription.products,
              items_drivers: this.items.values,
              attachments_vehicles: this.attachments.values,
              products: this.prescription.products,
              prescription: this.prescription.id,
              tools: toolsPlanning,
              area_effective: this.actualWorkOrder.planner.area_effective,
              location: this.actualLocationsSelected,
              task: this.taskSelected.id,
              comment: this.actualWorkOrder.additionalInfoWorkOrder,
              nursery: nurseryContent,
              performance: { total: parseFloat(this.kgCollected).toFixed(2), price: 0, unit: 'KGS' },
              close,
            };
            if ((this.showProducts && this.prescription.id !== undefined)
            || (!this.showProducts)) {
              await Api.editOrder(this.actualWorkOrder.id, newOrder);
              this.goToDailyWorkOrders(); // TODO: Habría que mostrar alguna notificación de que se editó correctamente
            } else {
              this.$f7.dialog.alert(this.$t('CreatePostWork_Validation_PrescriptionNeeded'));
            }
          }
        } catch (reason) {
          const message = this.$helpers.getFilteredErrorMessage(reason);
          if (message.includes('TASK_WORDER_PRODUCT_PERFORMANCE_REQUIRED')) {
            this.$f7.dialog.alert(this.$t(message));
          }
          if (message.includes('TASK_WORDER_MACHINERY_PERFORMANCE_INCOMPLETE')) {
            this.$f7.dialog.alert(this.$t(message));
          }
          if (message.includes('TASK_WORDER_PRODUCT_PERFORMANCE_REQUIRED')) {
            this.$f7.dialog.alert(this.$t(message));
          }
          if (message.includes('TASK_WORDER_PRODUCT_PERFORMANCE_TOTAL_REAL_PRESCRIPTION_ZERO')) {
            this.$f7.dialog.alert(this.$t(message));
          }
          if (message.includes('default_') && !message.includes('404')) {
            this.$f7.dialog.alert(message.split('_').pop());
          } else if (!message.includes('404')) {
            this.$f7.dialog.alert(this.$t(message));
          }
        } finally {
          this.$f7.preloader.hide();
        }
      }
    },
    calculateItems(items) {
      const arrayItems = [];
      const arrayNames = [];
      if (items.length > 0) {
        for (let i = 0; i < items.length; i += 1) {
          if (arrayNames.indexOf(items[i].type) >= 0) {
            arrayItems[arrayNames.indexOf(items[i].type)].count += 1;
          } else {
            arrayNames.push(items[i].type);
            arrayItems.push({ type: items[i].type, count: 1 });
          }
        }
      }
      return arrayItems;
    },
    calculateAttachments(attachments) {
      const arrayAttachments = [];
      const arrayNames = [];
      if (attachments.length > 0) {
        for (let i = 0; i < attachments.length; i += 1) {
          if (arrayNames.indexOf(attachments[i].type) >= 0) {
            arrayAttachments[arrayNames.indexOf(attachments[i].type)].count += 1;
          } else {
            arrayNames.push(attachments[i].item);
            // item se ha cambiado por type por la tarea SFR-17
            arrayAttachments.push({ type: attachments[i].type, count: 1 });
          }
        }
      }
      return arrayAttachments;
    },
    /**
     * Se debe invertir las herramientas porque tenemos una deuda técnica:
     * La tabla que se usa para visualizar/editar las herramientas tiene una lógica
     * errónea e invierte item por type. Debido a ello hacemos la inversión antes
     * de enviárselo al back
     */
    inverterTools(tools) {
      const toolsFormated = [];
      for (const tool of tools) {
        const newTool = {
          count: 1,
          type: tool.item,
          item: tool.type,
          fuel_consumption: tool.fuel_consumption,
          fuel_type: tool.fuel_type,
        };
        toolsFormated.push(newTool);
      }
      return toolsFormated;
    },
    checkCheckInWorkers() {
      for (const worker of this.employees) {
        if (moment(worker.init_date).format('YYYY-MM-DD') < this.dateSelected[0]) {
          return false;
        }
      }
      return true;
    },
    goToDailyWorkOrders() {
      this.$f7.views.main.router.navigate(`/${this.back}/`, { reloadCurrent: true });
    },
    ...mapActions('TaskManager', ['setTotalAreaSelected']),
    ...mapActions('Prescription', ['setNewPrescription']),
    ...mapActions('PlanningManager', ['setActualDailyPlanning', 'initStore', 'setShowProducts']),
  },
};
</script>
