<template>
  <f7-page>
    <navbar :text="$t('createOrderNavbar')" />
    <DxWorkOrderCreateGrouped
      v-if="actualDailyPlanning.Grouped"
      :actual-daily-planning="actualDailyPlanning"
    />
    <DxWorkOrderCreateNoGrouped
      v-else
      :actual-daily-planning="actualDailyPlanning"
    />
    <f7-block-footer
      class="content-action-botton"
    >
      <f7-button
        class="dx-btn-cancel margin-right"
        raised
        type="default"
        text-color="grey"
        styling-mode="outlined"
        @click="goToDailyPlanning"
      >
        {{ $t("CreateOrder_ButtonCancel_caption") }}
      </f7-button>
      <f7-button
        class="dx-btn-success"
        raised
        type="success"
        styling-mode="contained"
        name="CreateOrder_ButtonSave_caption"
        @click="createOrders"
      >
        {{ $t('CreateOrder_ButtonSave_caption') }}
      </f7-button>
    </f7-block-footer>
  </f7-page>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions, mapGetters } from 'vuex';
import navbar from '../components/NavBar.vue';
import DxWorkOrderCreateGrouped from '../components/workOrderDetail/DxWorkOrderCreateGrouped.vue';
import DxWorkOrderCreateNoGrouped from '../components/workOrderDetail/DxWorkOrderCreateNoGrouped.vue';
import Api from '../services/Api';

export default {
  name: 'CreateOrder',
  components: {
    navbar,
    DxWorkOrderCreateGrouped,
    DxWorkOrderCreateNoGrouped,
  },
  data() {
    return {
      newOrders: {},
    };
  },
  computed: {
    ...mapState('PlanningManager', ['actualDailyPlanning', 'actualWorkOrder', 'actualLocationsSelected', 'tools', 'attachments', 'locations', 'locationsCompletedId']),
    ...mapState('Prescription', ['prescription']),
    ...mapState('greenhouse', [
      'binsContentPlanned',
      'binsContentsForPlanning',
    ]),
    ...mapGetters('greenhouse', [
      'filteredContentsForPlanning',
      'getCategoryCodeByName',
      'getVarietyCodeByName',
      'getClassificationCodeByName',
    ]),
  },
  beforeDestroy() {
    this.setNewPrescription();
    this.resetStates();
  },
  methods: {
    goToDailyPlanning() {
      this.$f7.views.main.router.navigate('/dailyPlanning/', { reloadCurrent: true });
    },
    goToOrders(isHarvesting) {
      if (!isHarvesting) {
        this.$f7.views.main.router.navigate('/workOrders/', { reloadCurrent: true });
      } else {
        this.$f7.views.main.router.navigate('/harvesting/', { reloadCurrent: true });
      }
    },
    async createOrders() {
      if (typeof this.actualWorkOrder.itemsWorkOrder === 'undefined') {
        this.actualWorkOrder.itemsWorkOrder = [];
      }
      const contentArray = this.binsContentPlanned.map((element) => element.content);
      const contentSet = new Set(contentArray);
      if (typeof this.actualWorkOrder.supervisorsLocation === 'undefined' || this.actualWorkOrder.supervisorsLocation.length === 0) {
        this.$f7.dialog.alert(this.$t('CreateOrder_ValidationSupervisorsLocation'));
      } else if (!this.checkDate(this.actualDailyPlanning.Date)) {
        this.$f7.dialog.alert(this.$t('CreateOrder_ValidationOutDate'));
      } else if (contentArray.length > contentSet.size) {
        this.$f7.dialog.alert(this.$t('CreatePlanning_Duplicated_Nursery_Rows'));
      } else if (!this.checkItems(this.actualWorkOrder.itemsWorkOrder)) {
        this.$f7.dialog.alert(this.$t('CreateOrder_ValidationItemsWorkOrder'));
      } else if (!this.checkDrivers(this.actualWorkOrder.itemsDriversWorkOrder)) {
        this.$f7.dialog.alert(this.$t('CreateOrder_ValidationItemsDriversWorkOrder'));
      } else if (!this.checkAttachments()) {
        this.$f7.dialog.alert(this.$t('CreateOrder_ValidationAttachments'));
      } else {
        this.$f7.preloader.show();
        try {
          const nurseryContent = this.binsContentPlanned.map((element) => {
            const filteredContentAvailable = this.filteredContentsForPlanning(
              element.bin,
              element.variety,
              element.category,
              element.classification,
            );
            if (filteredContentAvailable.length > 0) {
              const binContentForPlanning = this.binsContentsForPlanning.filter((content) => content.bin.bin_id === element.bin);
              return {
                bin: binContentForPlanning[0].bin,
                category: this.getCategoryCodeByName(element.category),
                class_type: this.getClassificationCodeByName(element.classification),
                code: element.content,
                count: filteredContentAvailable[0].plants_count,
                variety: this.getVarietyCodeByName(element.variety),
              };
            }
            return [];
          });
          const items = this.getItems(this.actualWorkOrder.itemsWorkOrder);
          if (this.actualDailyPlanning.Grouped) {
            this.newOrders = {
              planner: this.actualDailyPlanning.ID,
              tasks: [{
                responsible: this.actualWorkOrder.supervisorsLocation,
                location: this.actualLocationsSelected,
                comment: this.actualWorkOrder.additionalInfoWorkOrder,
                items,
                items_drivers: this.actualWorkOrder.itemsDriversWorkOrder,
                products: this.prescription.products,
                prescription: this.prescription.id,
                attachments_vehicles: this.attachments.values,
                tools: this.tools.values,
                nursery: nurseryContent,
                locations_tree: this.locationsCompletedId,
              }],
            };
          } else {
            const tasks = [];
            let checked = true;
            for (const supervisorLocation of this.actualWorkOrder.supervisorsLocation) {
              if (supervisorLocation.location !== '' && supervisorLocation.supervisor !== '') {
                tasks.push({
                  responsible: supervisorLocation.supervisor,
                  location: [supervisorLocation.location],
                  comment: this.actualWorkOrder.additionalInfoWorkOrder,
                  items,
                  items_drivers: this.actualWorkOrder.itemsDriversWorkOrder,
                  products: this.prescription.products,
                  prescription: this.prescription.id,
                  attachments_vehicles: this.attachments.values,
                  tools: this.tools.values,
                  nursery: nurseryContent,
                  locations_tree: this.locationsCompletedId,
                });
              } else {
                checked = false;
                this.$f7.dialog.alert(this.$t('CreateOrder_ValidationSupervisorsLocation'));
                break;
              }
            }
            if (checked) {
              this.newOrders = {
                planner: this.actualDailyPlanning.ID,
                tasks,
              };
            }
          }
          const respuesta = await Api.createOrders(this.newOrders);
          if (respuesta.status === 409) {
            const message = this.$helpers.getFilteredErrorMessage(respuesta.response);
            if (message.includes('default_') && !message.includes('404')) {
              this.$f7.dialog.alert(message.split('_').pop());
            } else if (!message.includes('404')) {
              this.$f7.dialog.alert(this.$t(message));
            }
          } else {
            this.goToOrders((this.actualDailyPlanning.activityCode === '08')); // TODO: Habría que mostrar alguna notificación de que se creó correctamente
          }
        } catch (reason) {
          const message = this.$helpers.getFilteredErrorMessage(reason);
          if (message.includes('default_') && !message.includes('404')) {
            this.$f7.dialog.alert(message.split('_').pop());
          } else if (!message.includes('404')) {
            this.$f7.dialog.alert(this.$t(message));
          }
        } finally {
          this.$f7.preloader.hide();
        }
      }
    },
    getItems(items) {
      let ids = [];
      for (const item of items) {
        ids = ids.concat(item.ids);
      }
      return ids;
    },
    getCountItemsbySubfamily(itemsWorkOrder, subfamily) {
      for (const itemWorkOrder of itemsWorkOrder) {
        if (itemWorkOrder.subfamily === subfamily) {
          return itemWorkOrder.ids.length;
        }
      }
      return 0;
    },
    checkItems(itemsWorkOrder) {
      for (const itemsDetail of this.actualDailyPlanning.ItemsDetailTab) {
        if (itemsDetail.count !== this.getCountItemsbySubfamily(itemsWorkOrder, itemsDetail.type)) {
          return false;
        }
      }
      return true;
    },
    checkDrivers(itemsDriversWorkOrder) {
      // Los items no son obligatorios, sólo se deben chequear cuando existan
      if (typeof itemsDriversWorkOrder === 'undefined' || itemsDriversWorkOrder === null) {
        return true;
      }
      for (const item of itemsDriversWorkOrder) {
        if (item.driver === '') {
          return false;
        }
      }
      return true;
    },
    checkDate(dates) {
      for (const date of dates) {
        const formatDate = moment(date, 'DD/MM/YYYY').toDate();
        const varDate = moment(formatDate).format('YYYYMMDD'); // dd-mm-YYYY
        const today = moment().format('YYYYMMDD');
        if (varDate < today) return false;
      }
      return true;
    },
    checkAttachments() {
      if (this.actualDailyPlanning.AttachmentsDetailTab.length === 0) {
        return true;
      }
      for (const attachment of this.attachments.values) {
        if (typeof attachment.vehicle === 'undefined' || attachment.vehicle === '') {
          return false;
        }
      }
      return true;
    },
    ...mapActions('Prescription', ['setNewPrescription']),
    ...mapActions('greenhouse', ['resetStates']),
  },
};
</script>
